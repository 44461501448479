import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Tooltip } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Footer = () => {

    const [apiRes, setApiRes] = useState({ show: false, message: '', severity: '' });

    const handleCopyEmail = async () => {
        await navigator.clipboard.writeText('comercializadorajuvalfri@gmail.com');
        setApiRes(state => { return { ...state, show: true, message: 'Correo copiado al portapapeles', severity: 'success' } });
    }

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show } });
    }

    return (
        <>
            <Container maxWidth='xxl' style={{ padding: '0px', margin: '0px' }}>
                <div style={{ height: '200px', background: 'linear-gradient(360deg, rgba(13,17,23,1) 35%, #171726 100%)' }}></div>
            </Container>
            <Container maxWidth='xxl'>
                <Container maxWidth='xl'>
                    <Grid container spacing={1} sx={{ marginBottom: '128px', marginTop: '32px' }}>
                        <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography sx={{ marginTop: '16px', textAlign: 'center', fontWeight: '700', fontSize: '24px', color: '#FFF' }}>
                                JUVALFRI
                            </Typography>
                            <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: '14px', color: '#7D8590', padding: '0px 16px 0px 16px', marginBottom: '16px' }}>
                                Comercializadora de Servicios Integrales Juvalfri
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                                <p style={{ textAlign: 'start', fontWeight: '600', fontSize: '20px', color: '#FFF', padding: '0px 16px 0px 16px', marginBottom: '0px' }}>
                                    Ponte en contacto
                                </p>
                                <Link to='https://wa.me/message/7XOWX4XGRUGWO1' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Tooltip title='Iniciar Chat' placement='right'>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} className='zoomIcon whatsLink-f'>
                                            <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '13px', padding: '0px 16px 0px 16px', marginBottom: '0px' }}>
                                                <WhatsAppIcon fontSize='medium' />
                                            </p>
                                            <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '15px', marginBottom: '0px' }}>
                                                55-48-61-73-32
                                            </p>
                                        </div>
                                    </Tooltip>
                                </Link>
                                <Tooltip title='Copiar correo electrónico'>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} onClick={() => handleCopyEmail()} className='zoomIcon mailLink-f'>
                                        <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '13px', padding: '0px 16px 0px 16px', marginBottom: '0px' }}>
                                            <EmailIcon fontSize='medium' />
                                        </p>
                                        <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '15px', marginBottom: '0px' }}>
                                            comercializadorajuvalfri@gmail.com
                                        </p>
                                    </div>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                                <p style={{ textAlign: 'start', fontWeight: '600', fontSize: '20px', color: '#FFF', padding: '0px 16px 0px 16px', marginBottom: '0px' }}>
                                    Nos encontramos en
                                </p>
                                <Link to='https://maps.app.goo.gl/LEURbR4gVu4ytxVu9' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Tooltip title='Ver direccion en Google Maps'>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} className='zoomIcon mapsLink-f'>
                                            <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '15px', padding: '0px 16px 0px 16px' }}>
                                                <PlaceIcon fontSize='large' />
                                            </p>
                                            <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '15px' }}>
                                                Niza 19 N, San Álvaro, Azcapotzalco, 02090, CDMX, México.
                                            </p>
                                        </div>
                                    </Tooltip>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Container maxWidth='xxl' sx={{ background: '#161b22', color: '#7d8590', padding: '24px 16px 24px 16px' }}>
                <Container maxWidth='xl' sx={{ display: { xs: 'none', md: 'block' } }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <div>
                            <Typography sx={{ textAlign: 'start', fontSize: '12px' }}>
                                © {new Date().getFullYear()} | Comercializadora de Servicios Integrales Juvalfri S.A. de C.V.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '32px' }}>
                            <a
                                href='https://www.facebook.com/Juvalfri'
                                target='_blanck'
                                style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                                aria-label='Facebook'
                                className='zoomIcon'
                            >
                                <FacebookIcon />
                            </a>
                            <a
                                href='https://www.instagram.com/juvalfri/'
                                target='_blanck'
                                style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                                aria-label='Instagram'
                                className='zoomIcon'
                            >
                                <InstagramIcon />
                            </a>
                            <a
                                href='https://twitter.com/juvalfri'
                                target='_blanck'
                                style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                                aria-label='Twitter'
                                className='zoomIcon'
                            >
                                <TwitterIcon />
                            </a>
                            <a
                                href='https://www.linkedin.com/company/comercializadora-de-servicios-integrales-juvalfri-s-a-de-c-v/'
                                target='_blanck'
                                style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                                aria-label='Linkedin'
                                className='zoomIcon'
                            >
                                <LinkedInIcon />
                            </a>
                        </div>
                    </div>
                </Container>
                <Container maxWidth='xl' sx={{ display: { xs: 'block', md: 'none' } }}>
                    <div style={{ display: 'flex', justifyContent: 'start', paddingBottom: '16px' }}>
                        <a
                            href='https://www.facebook.com/Juvalfri'
                            target='_blanck'
                            style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 0px' }}
                            aria-label='Facebook'
                        >
                            <FacebookIcon />
                        </a>
                        <a
                            href='https://www.instagram.com/juvalfri/'
                            target='_blanck'
                            style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                            aria-label='Instagram'
                        >
                            <InstagramIcon />
                        </a>
                        <a
                            href='https://twitter.com/juvalfri'
                            target='_blanck'
                            style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                            aria-label='Twitter'
                        >
                            <TwitterIcon />
                        </a>
                        <a
                            href='https://www.linkedin.com/company/comercializadora-de-servicios-integrales-juvalfri-s-a-de-c-v/'
                            target='_blanck'
                            style={{ textDecoration: 'none', color: 'inherit', lineHeight: '0px', padding: '0px 10px 0px 10px' }}
                            aria-label='Linkedin'
                        >
                            <LinkedInIcon />
                        </a>
                    </div>
                    <div>
                        <Typography sx={{ textAlign: 'start', fontSize: '12px' }}>
                            © {new Date().getFullYear()} | Comercializadora de Servicios Integrales Juvalfri S.A. de C.V. | Todos los derechos reservados.
                        </Typography>
                    </div>
                </Container>
            </Container>
            <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
        </>

    );
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomMessage = (props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.handleCloseMessage(false);
        setOpen(false);
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
                    {props.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

export default Footer;
