import styled from 'styled-components';

export const StyledDivider = styled.div({
    marginTop: '128px',
    overflow: 'hidden',
    marginBottom: '72px',

    '& img': {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',

        '@media (max-width: 1200px)': {
            width: '1800px',
            marginLeft: '-600px'
        },

        '@media (max-width: 900px)': {
            width: '1500px',
            marginLeft: '-600px'
        },

        '@media (max-width: 700px)': {
            width: '1500px',
            marginLeft: '-600px'
        },

        '@media (max-width: 600px)': {
            width: '1500px',
            marginLeft: '-800px'
        },

        '@media (max-width: 420px)': {
            width: '1500px',
            marginLeft: '-900px'
        },
    },

})

export const StyledTitle = styled.p({
    fontSize: '72px',
    fontWeight: '800',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '24px',
    lineHeight: '72px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '0px',
    textAlign: 'center',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledSubtitle = styled.p({
    fontSize: '32px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '0px',
    marginTop: '0px',
    textAlign: 'center',
    lineHeight: '44px',
    color: '#7D8590',
    paddingLeft: '12px',

    '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '20px',
        lineHeight: '28px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
    },
})
