import styled from 'styled-components'

export const StyledSubtitle = styled.p({
    fontSize: '32px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '0px',
    marginTop: '0px',
    maxWidth: '910px',
    lineHeight: '44px',
    color: '#7D8590',
    paddingLeft: '12px',

    '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledSecondaryTitle = styled.p({
    fontSize: '48px',
    maxWidth: '910px',
    fontWeight: '500',
    transition: 'all 0.3s ease-in-out',
    lineHeight: '52px',
    color: '#FFF',
    paddingBottom: '72px',
    paddingLeft: '12px',

    '@media (max-width: 600px)': {
        fontSize: '28px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
        paddingBottom: '64px',
    },

    '@media (max-width: 420px)': {
        fontSize: '28px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#939aff',
    }
})

export const StyledRowTextImg = styled.div({
    display: 'flex',
    backgroundColor: '#161b22',
    color: '#FFF',
    borderRadius: '12px',
    padding: '64px',
    border: 'solid 0.2px #2c3239',
    marginBottom: '86px',

    '@media (max-width: 900px)': {
        padding: '32px',
    },

    '& div': {
        '&.txt-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',

            '@media (max-width: 900px)': {
                alignItems: 'center',
                textAlign: 'center',
            },
        },

        '&.img-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            '@media (max-width: 900px)': {
                paddingTop: '64px',
                alignItems: 'center',
                textAlign: 'center',
            },
        }
    },

    '& p': {
        width: '100%',

        '&.title': {
            fontSize: '48px',
            marginTop: '0px',
            marginBottom: '32px',
            lineHeight: '52px',
            fontWeight: '700',
            transition: 'all 0.3s ease-in-out',

            '@media (max-width: 1200px)': {
                fontSize: '40px',
                lineHeight: '48px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '40px',
                lineHeight: '48px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '38px',
                lineHeight: '42px',
                fontWeight: '600',
            },
        },

        '&.subtitle': {
            fontSize: '28px',
            lineHeight: '38px',
            fontWeight: '600',
            margin: '0px',
            transition: 'all 0.3s ease-in-out',
            color: '#FFF',

            '@media (max-width: 1200px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '22px',
                lineHeight: '28px',
                fontWeight: '500',
            },
        }
    },
})

