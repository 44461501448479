import { React, useEffect } from 'react';
import { Container, Box } from '@mui/material';
import './style.css'

import JuvalfriAnimation from './JuvalfriAnimation';

import { StyledTitle, StyledSubtitle, StyledDivJuvalfri } from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";
import ServicioHome from './servicio/ServicioHome';
import MembranasHome from './membranas/MembranasHome';
import FabricacionHome from './fabricacion/FabricacionHome';
import FinalHome from './final/FinalHome';
import MainBackground from '../layout/MainBackground';

const Home = () => {


    useEffect(() => {
        AOS.init({ duration: 400 })
        document.title = "Juvalfri";
    }, [])
    return (
        <>
            <Container maxWidth='xxl' style={{margin: '0px', padding: '0px'}}>
                <MainBackground />
            </Container>
            <Container maxWidth='xl'>
                <Box sx={{ marginTop: '128px', paddingTop: '128px' }}>
                    <StyledTitle style={{ textShadow: '2px 2px 4px #0d1117' }} data-aos="fade-up" as='h1'>Descubre nuestra gama de servicios</StyledTitle>
                    <StyledSubtitle style={{ textShadow: '1px 1px 2px #0d1117' }} data-aos="fade-up">
                        Mantenimiento y Reparación de Equipo Médico. 
                        Fabricación de teclados de Membrana. 
                        Fabricación de piezas y refacciones. 
                        Ingeniería y desarrollo.</StyledSubtitle>
                </Box>
            </Container >

            <Container maxWidth='xl' id='servicio'>
                <StyledDivJuvalfri data-aos="zoom-in">
                {/* <JuvalfriAnimation /> */}
                    <img src='./images/Juvalfri-navbar.svg' alt='logo-juvalfri'></img>
                </StyledDivJuvalfri>
                <ServicioHome />
            </Container>

            <Container maxWidth='xxl' style={{ padding: '0px', margin: '0px', backgroundColor: '#1B2133' }} id='membranas'>
                <MembranasHome />
            </Container>
            <Container maxWidth='xl' id='manufactura' sx={{ overflowY: 'hidden' }}>
                <FabricacionHome />
            </Container>
            <Container maxWidth='xxl' style={{ padding: '0px', margin: '0px', backgroundColor: '#171726' }}>
                <FinalHome />
            </Container>
        </>
    );
};

export default Home;
