import styled from 'styled-components'

export const StyledTitle = styled.p({
    fontSize: '90px',
    fontWeight: '600',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '24px',
    marginTop: '0px',
    maxWidth: '910px',
    lineHeight: '100px',
    color: '#FFF',
    paddingLeft: '12px',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledSubtitle = styled.p({
    fontSize: '32px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '0px',
    marginTop: '0px',
    maxWidth: '910px',
    lineHeight: '44px',
    color: '#7D8590',
    paddingLeft: '12px',

    '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledDivJuvalfri = styled.div({
    height: '720px',
    marginTop: '86px',
    maxWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s ease-in-out',

    '& img': {
        width: '100%',
        maxWidth: '300px',
        height: 'auto',
        transition: 'all 0.3s ease-in-out',
        animation: 'zoom 1.8s ease-in-out infinite alternate',

        '@keyframes zoom': {
            '0%': {
                transform: 'scale(0.92)',
            },
            '100%': {
                transform: 'scale(1)',
            }
        }
    },

    '@media (max-width: 1670px)': {
        height: '450px',
    },

    '@media (max-width: 1530px)': {
        height: '350px',
    },

    '@media (max-width: 1380px)': {
        height: '250px',
    },

    '@media (max-width: 1280px)': {
        height: '250px',
    },

    '@media (max-width: 960px)': {
        height: '100px',
        marginBottom: '86px',
    },

    '@media (max-width: 600px)': {
        marginBottom: '86px',
    },

    '@media (max-width: 420px)': {
        marginBottom: '86px',
    },
})
