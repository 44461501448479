import { React, useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import './style.css'

import { StyledSubtitle, StyledSecondaryTitle, StyledRowTextImg } from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";
import MonitorAnimation from '../MonitorAnimation';

const ServicioHome = () => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const slides = [
        { src: './images/equipo-1.webp', width: 1198, height: 1199 },
        { src: './images/equipo-2.webp', width: 1198, height: 1199 },
        { src: './images/equipo-3.webp', width: 1198, height: 1199 },
        { src: './images/equipo-4.webp', width: 1198, height: 1199 },
        { src: './images/equipo-5.webp', width: 1198, height: 1199 },
        { src: './images/equipo-6.webp', width: 1198, height: 1199 },
        { src: './images/equipo-7.webp', width: 1198, height: 1199 },
        { src: './images/equipo-8.webp', width: 1198, height: 1199 },
        { src: './images/equipo-9.webp', width: 1198, height: 1199 },
        { src: './images/equipo-10.webp', width: 1198, height: 1199 },
        { src: './images/equipo-11.webp', width: 1198, height: 1199 },
        { src: './images/equipo-12.webp', width: 1198, height: 1199 },
    ];

    const toggleOpen = (state) => () => setOpen(state);

    const updateIndex = ({ index: current }) => setIndex(current);

        useEffect(() => {
            AOS.init({ duration: 400 })
        }, [])

    return (
        <>
            <Box>
                <StyledSecondaryTitle data-aos="fade-up">
                    <span>Ofrecemos servicios de reparación y mantenimiento de equipo médico. </span>
                    Con años de experiencia y un equipo experto, garantizamos el rendimiento y la durabilidad de tus equipos.
                </StyledSecondaryTitle>
            </Box>

            <Box data-aos="zoom-in">
                <StyledRowTextImg>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={7} className='txt-container'>
                            <p className='title' style={{ color: '#7ee787' }}>Reparamos una amplia variedad de equipos</p>
                            <p className='subtitle'>Monitores de Signos Vitales</p>
                            <p className='subtitle'>Electrocardiógrafos</p>
                            <p className='subtitle'>Monitores Fetales</p>
                            <p className='subtitle'>Mesas Quirúrgicas</p>
                            <p className='subtitle'>y muchos más ...</p>
                        </Grid>
                        <Grid item xs={12} md={6} lg={5} className='img-container'>
                            {/* <ImageSlider /> */}
                            <Lightbox
                                index={index}
                                slides={slides}
                                plugins={[Inline]}
                                on={{
                                    view: updateIndex,
                                    click: toggleOpen(true),
                                }}
                                carousel={{
                                    padding: 0,
                                    spacing: 0,
                                    imageFit: "cover",
                                }}
                                inline={{
                                    style: {
                                        width: "100%",
                                        height: 'auto',
                                        aspectRatio: "1 / 1",
                                        margin: "0 auto",
                                        borderRadius: '20px'
                                    },
                                }}
                            />
                            <Lightbox
                                open={open}
                                close={toggleOpen(false)}
                                index={index}
                                slides={slides}
                                on={{ view: updateIndex }}
                                animation={{ fade: 0 }}
                                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
                            />
                        </Grid>
                    </Grid>
                </StyledRowTextImg>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <div>
                    <StyledSecondaryTitle data-aos="zoom-in" style={{ textAlign: 'center', maxWidth: '100%', paddingBottom: '32px', fontWeight: '600' }}>
                        ¿Por qué elegir nuestros servicios?
                    </StyledSecondaryTitle>
                </div>
                <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <Grid item sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                        <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', marginBottom: '16px', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                            <img src='./images/icon-monitor.svg' alt='img' style={{ width: '100%' }}></img>
                        </div>
                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                            Equipos de Medición
                        </StyledSubtitle>
                        <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                            Utilizamos equipos de medición de alta calidad con certificación vigente.
                        </StyledSubtitle>
                    </Grid>
                    <Grid item sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                        <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', marginBottom: '16px', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                            <img src='./images/icon-users.svg' alt='img' style={{ width: '100%' }}></img>
                        </div>
                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                            Personal Capacitado
                        </StyledSubtitle>
                        <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                            Contamos con personal especializado en el área Biomédica.
                        </StyledSubtitle>
                    </Grid>
                    <Grid item sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                        <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', marginBottom: '16px', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                            <img src='./images/icon-price.svg' alt='img' style={{ width: '100%' }}></img>
                        </div>
                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                            Calidad y precio
                        </StyledSubtitle>
                        <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                            Ofrecemos precios competitivos y garantía en nuestros servicios.
                        </StyledSubtitle>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <div>
                    <StyledSecondaryTitle data-aos="zoom-in" style={{ textAlign: 'center', maxWidth: '100%', paddingBottom: '32px', fontWeight: '600' }}>
                        ¿Por que elegir nuestros <span>servicios</span>?
                    </StyledSecondaryTitle>
                </div>
                <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'start' }}>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                        <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                            <img src='./images/icon-monitor.svg' alt='img' style={{ width: '100%' }}></img>
                        </div>
                        <div style={{ marginLeft: '12px' }}>
                            <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                Equipos de Medición
                            </StyledSubtitle>
                            <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                                Utilizamos equipos de medición de alta calidad con certificación vigente.
                            </StyledSubtitle>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                        <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                            <img src='./images/icon-users.svg' alt='img' style={{ width: '100%' }}></img>
                        </div>
                        <div style={{ marginLeft: '12px' }}>
                            <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                Personal Capacitado
                            </StyledSubtitle>
                            <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                                Contamos con personal especializado en el área Biomédica.
                            </StyledSubtitle>
                        </div>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                        <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                            <img src='./images/icon-price.svg' alt='img' style={{ width: '100%' }}></img>
                        </div>
                        <div style={{ marginLeft: '12px' }}>
                            <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                Calidad y precio
                            </StyledSubtitle>
                            <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                                Ofrecemos precios competitivos y garantía en nuestros servicios.
                            </StyledSubtitle>
                        </div>
                    </Grid>
                </Grid>
            </Box>

            <Box data-aos="zoom-in" style={{marginTop: '128px'}}>
                <StyledRowTextImg>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={5} className='img-container'>
                            <div>
                                <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                                    <MonitorAnimation width={300} />
                                </Box>
                                <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                                    <MonitorAnimation width={400} />
                                </Box>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={7} className='txt-container'>
                            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                                <Typography className='title' style={{ color: '#9b8aff', marginTop: '68px' }}>Características de nuestros servicios</Typography>
                            </Box>
                            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                                <Typography className='title' style={{ color: '#9b8aff' }}>Características de nuestros servicios</Typography>
                            </Box>
                            <ul>
                                <li><Typography variant='h6'>Experiencia y conocimiento técnico.</Typography></li>
                                <li><Typography variant='h6'>Fabricación de piezas y componentes.</Typography></li>
                                <li><Typography variant='h6'>Procesos de control de calidad.</Typography></li>
                                <li><Typography variant='h6'>Actualización tecnológica constante.</Typography></li>
                                <li><Typography variant='h6'>Compromiso con la innovación en servicios.</Typography></li>
                                <li><Typography variant='h6'>Enfoque en atención al cliente excepcional.</Typography></li>
                            </ul>
                        </Grid>
                    </Grid>
                </StyledRowTextImg>
            </Box>
        </>


    );
};

export default ServicioHome;
