import { React, useEffect } from 'react';
import { Container } from '@mui/material';

import JuvalfriAnimation from '../JuvalfriAnimation';

import { StyledTitle, StyledSubtitle, StyledDivider } from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";

const FinalHome = () => {

    useEffect(() => {
        AOS.init({ duration: 400 })
    }, [])

    return (
        <>
            <Container maxWidth='xxl' style={{ background: '#171726', paddingLeft: '0px', paddingRight: '0px' }}>
                <StyledDivider>
                    <img src='./images/section-space-v3-2.svg' alt='div-img' />
                </StyledDivider>
            </Container>
            <Container maxWidth='xl' sx={{ marginTop: '180px' }}>
                <StyledTitle >
                    ¡Contáctanos ahora y descubre cómo podemos ayudarte a alcanzar tus objetivos!
                </StyledTitle>
                <StyledSubtitle>
                    Nuestro equipo está a tu disposición para brindarte la mejor asesoría.
                </StyledSubtitle>
            </Container>
            <Container maxWidth='xl' style={{ paddingTop: '128px', paddingBottom: '128px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <JuvalfriAnimation />
            </Container>
        </>


    );
};

export default FinalHome;
