import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import { createBrowserHistory } from 'history';

// google analytics
import { useEffect } from 'react';
import ReactGA from "react-ga4";

import { Container, Fab } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import './App.css';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Home from './components/home/Home';

// theme provider
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import materialTheme from './components/layout/materialTheme';
import Membranas from "./components/membranas/Membranas";
import Contacto from "./components/contacto/Contacto";
import Manufactura from "./components/manufactura/Manufactura";
import CatalogoMembranas from "./components/catalogs/CatalogoMembranas";
import CatalogoPiezas from "./components/catalogs/CatalogoPiezas";

// theme stuff
const theme = createTheme(materialTheme);

// google analytics
const TRACKING_ID = "G-KT44FXJFF1";
ReactGA.initialize(TRACKING_ID);

const getPageTitle = (p) => {
    switch (p) {
        case '/':
            return 'Home';
        case '/membranas':
            return 'Membranas';
        case '/contacto':
            return 'Contacto';
        case '/manufactura':
            return 'Manufactura';
        case '/catalogoMembranas':
            return 'Catalogo Membranas';
        case '/catalogoRefacciones':
            return 'Catalogo Refacciones';
        default:
            return 'Home';
    }
}

// Función para rastrear la página actual
const trackPage = (page) => {
    ReactGA.send({ hitType: "pageview", page: page, title: getPageTitle(page) });
};

// Componente para realizar el seguimiento de la página actual
const TrackPageView = () => {
    const location = useLocation();

    useEffect(() => {
        // Rastrear la página actual cuando cambie la ubicación
        trackPage(location.pathname);
    }, [location]);

    return null; // No renderizamos nada ya que este componente solo maneja el efecto
};

// layout components
const AppRoutes = () => {


    return (
        <Router>
            <TrackPageView />
            <div className="App">
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Container maxWidth='xxl' style={{ margin: '0px', padding: '0px' }}>
                        <Navbar />
                    </Container>

                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path='/membranas' element={<Membranas />} />
                        <Route exact path='/contacto' element={<Contacto />} />
                        <Route exact path='/manufactura' element={<Manufactura />} />
                        <Route exact path='/catalogoMembranas' element={<CatalogoMembranas />} />
                        <Route exact path='/catalogoRefacciones' element={<CatalogoPiezas />} />
                    </Routes>

                    <div style={{ position: 'fixed', bottom: '0px', left: '12px', display: 'flex', zIndex: 100 }} className="zoomIcon">
                        <Link to='https://wa.me/message/7XOWX4XGRUGWO1' target="_blank">
                            <img src="./images/WhatsApp.png" style={{ width: '56px', height: 'auto' }} />
                        </Link>
                    </div>

                    <Fab aria-label="top" sx={{ position: 'fixed', bottom: '12px', right: '12px' }} color='info' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <ChevronLeftIcon sx={{ transform: 'rotate(90deg)' }} />
                    </Fab>
                    <Container maxWidth='xxl' style={{ padding: '0px', margin: '0px' }}>
                        <Footer />
                    </Container>
                </ThemeProvider>

            </div>
        </Router>
    )
}

export default AppRoutes;