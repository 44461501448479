import { React, useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import CatalogoPiezas from '../../catalogs/CatalogoPiezas';

import { StyledTitle, StyledSubtitle, StyledRowTextImg, StyledFinalTitle, StyledFinalSubtitle, StyledButton, StyledButtonMas } from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";

const FabricacionHome = () => {

    const [showCatalogo, setShowCatalogo] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 400 })
    }, [])

    const handleShowCatalog = (e) => {
        e.preventDefault();
        setShowCatalogo(!showCatalogo);
    }

    return (
        <>
            <Box>
                <StyledTitle data-aos="fade-up" as='h1'>
                    Piezas de precisión
                </StyledTitle>
                <StyledSubtitle data-aos='fade-up'>
                    Utilizamos impresoras 3D, torno y fresa CNC de última generación, lo que nos permite fabricar
                    piezas de alta precisión con rapidez y eficiencia
                </StyledSubtitle>
                <StyledButtonMas onClick={() => navigate('/manufactura')}>Conocer mas</StyledButtonMas>
            </Box>

            <Box data-aos="fade-up">
                <StyledRowTextImg>
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6} lg={7} className='img-container'>
                            <picture>
                                <source srcSet='./images/impresion-1.webp' type='image/webp' />
                                <source srcSet='./images/impresion-1.png' type='image/png' />
                                <img src='./images/impresion-1.webp' alt='img' style={{ maxWidth: '730px', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                            </picture>
                        </Grid>
                        <Grid item sm={12} md={6} lg={5} className='txt-container'>
                            <p className='title' style={{ color: '#7ee787' }} as='h2'>Impresión 3D</p>
                            <p className='subtitle'>Fabricamos piezas con impresión 3D utilizando Resinas y termoplásticos de la mejor calidad.</p>
                            <p className='subsubtitle'>Podemos replicar piezas completas o hacer nuevos diseños dependiendo de tus necesidades.</p>
                        </Grid>
                    </Grid>
                </StyledRowTextImg>
            </Box>

            <Box data-aos="fade-up">
                <StyledRowTextImg>
                    <Grid container spacing={1}>
                        <Grid item sm={12} md={6} lg={5} className='txt-container'>
                            <p className='title' style={{ color: '#939aff' }} as='h2'>Maquinado CNC</p>
                            <p className='subtitle'>Ofrecemos servicios de manufactura utilizando la tecnología CNC para fabricar piezas de alta calidad.</p>
                            <p className='subsubtitle'>Utilizamos diversos materiales como acero inoxidable, aluminio, plásticos y más. </p>
                        </Grid>
                        <Grid item sm={12} md={6} lg={7} className='img-container'>
                            <picture>
                                <source srcSet='./images/maquinado-1.webp' type='image/webp' />
                                <source srcSet='./images/maquinado-1.png' type='image/png' />
                                <img src='./images/maquinado-1.webp' alt='img' style={{ maxWidth: '730px', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                            </picture>
                        </Grid>
                    </Grid>
                </StyledRowTextImg>
            </Box>

            <StyledButton style={{ marginBottom: `${showCatalogo ? '0px' : '128px'}`, marginTop: '56px' }} onClick={(e) => handleShowCatalog(e)}>{showCatalogo ? 'Ocultar' : 'Ver Catálogo'}</StyledButton>
            {showCatalogo ? <CatalogoPiezas /> : null}

            <Box>
                <StyledFinalTitle data-aos="fade-up">Algunos beneficios de la fabricación personalizada</StyledFinalTitle>

                <StyledFinalSubtitle data-aos="fade-up">
                    <span>Solución a problemas de obsolescencia:</span>
                </StyledFinalSubtitle>
                <div style={{ maxWidth: '910px' }}>
                    <hr style={{ color: '#888' }}></hr>
                </div>
                <StyledFinalSubtitle style={{ marginBottom: '72px' }} data-aos="fade-up">Ofrecemos soluciones a problemas de
                    obsolescencia de piezas, permitiendo a los clientes prolongar la vida útil de sus equipos y ahorrar costos en reemplazos
                    completos.
                </StyledFinalSubtitle>

                <StyledFinalSubtitle data-aos="fade-up">
                    <span>Flexibilidad en la producción:</span>
                </StyledFinalSubtitle>
                <div style={{ maxWidth: '910px' }}>
                    <hr style={{ color: '#888' }}></hr>
                </div>
                <StyledFinalSubtitle style={{ marginBottom: '72px' }} data-aos="fade-up">Podemos producir piezas personalizadas según
                    las necesidades de nuestros clientes, ofreciendo soluciones a medida para problemas específicos.</StyledFinalSubtitle>

                <StyledFinalSubtitle data-aos="fade-up">
                    <span>Ahorro de costos:</span>
                </StyledFinalSubtitle>
                <div style={{ maxWidth: '910px' }}>
                    <hr style={{ color: '#888' }}></hr>
                </div>
                <StyledFinalSubtitle style={{ marginBottom: '72px' }} data-aos="fade-up">Al ofrecer soluciones de reparación y reemplazo de piezas obsoletas,
                    nuestros clientes pueden ahorrar costos en la compra de equipos nuevos y en la producción de piezas en pequeñas cantidades.
                </StyledFinalSubtitle>
            </Box>


        </>
    );
};

export default FabricacionHome;
