import styled from 'styled-components'

export const StyledTitle = styled.p({
    fontSize: '72px',
    fontWeight: '800',
    maxWidth: '910px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '24px',
    lineHeight: '72px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '0px',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledSubtitle = styled.p({
    fontSize: '32px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '48px',
    marginTop: '0px',
    maxWidth: '910px',
    lineHeight: '44px',
    color: '#7D8590',
    paddingLeft: '12px',

    '@media (max-width: 900px)': {
        maxWidth: '600px',
        fontSize: '26px',
        lineHeight: '34px',
    },

    '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '20px',
        lineHeight: '28px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
    },
})

export const StyledRowTextImg = styled.div({
    display: 'flex',
    // backgroundColor: '#161b22',
    color: '#FFF',
    borderRadius: '12px',
    // padding: '64px',
    // border: 'solid 0.2px #2c3239',
    marginBottom: '86px',
    paddingTop: '86px',

    '@media (max-width: 900px)': {
        padding: '12px',
    },

    '@media (max-width: 600px)': {
        paddingTop: '12px',
        padding: '12px',
    },

    '& div': {
        '&.txt-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            paddingLeft: '24px',

            '@media (max-width: 600px)': {
                paddingLeft: '8px',
            },
        },

        '&.img-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            '@media (max-width: 900px)': {
                paddingTop: '64px',
                alignItems: 'center',
                textAlign: 'center',
            },

            '& img': {
                width: '100%',
            },
        }
    },

    '& p': {
        width: '100%',

        '&.title': {
            fontSize: '48px',
            marginTop: '12px',
            marginBottom: '32px',
            lineHeight: '52px',
            fontWeight: '700',
            transition: 'all 0.3s ease-in-out',

            '@media (max-width: 1200px)': {
                fontSize: '44px',
                lineHeight: '50px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '44px',
                lineHeight: '50px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '38px',
                lineHeight: '42px',
                fontWeight: '600',
            },
        },

        '&.subtitle': {
            fontSize: '28px',
            lineHeight: '38px',
            fontWeight: '400',
            margin: '0px',
            transition: 'all 0.3s ease-in-out',
            color: '#FFF',

            '@media (max-width: 1200px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '22px',
                lineHeight: '28px',
                fontWeight: '500',
            },
        },

        '&.subsubtitle': {
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: '400',
            margin: '0px',
            paddingTop: '16px',
            transition: 'all 0.3s ease-in-out',
            color: '#7D8590',

            '@media (max-width: 1200px)': {
                fontSize: '19px',
                lineHeight: '27px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '19px',
                lineHeight: '27px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '18px',
                lineHeight: '26px',
                fontWeight: '500',
            },
        }
    },
})

export const StyledFinalTitle = styled.p({
    fontSize: '48px',
    fontWeight: '800',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '64px',
    lineHeight: '72px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '0px',
    textAlign: 'center',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledFinalSubtitle = styled.p({
    fontSize: '22px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '16px',
    marginTop: '0px',
    textAlign: 'start',
    maxWidth: '910px',
    lineHeight: '30px',
    color: '#FFF',
    paddingLeft: '12px',

    '@media (max-width: 900px)': {
        maxWidth: '600px',
    },

    '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '20px',
        lineHeight: '28px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
        fontWeight: '600',
    },
})

export const StyledButton = styled.button({

    width: '200px',
    height: '70px',
    borderRadius: '20px',
    border: 'solid 2px #9b8aff',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #9b8aff',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #CbBaff',
        backgroundColor: '#00000022',
    },
})

export const StyledButtonMas = styled.button({
    width: '200px',
    height: '70px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    background: 'linear-gradient(60deg, #5b4aff 0%, #8b7aff 100%)',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',
    boxShadow: '3px 3px 10px #0d1117',

    '&:hover': {
        background: 'linear-gradient(60deg, #4b3aff 25%, #9b8aff 100%)',
        transition: 'all 0.2s ease-in-out',
        boxShadow: '3px 3px 10px #FFF',
    },

    '&:active': {
        background: 'linear-gradient(60deg, #2b1aDD 25%, #9b8aff 100%)',
        transition: 'all 0.2s ease-in-out',
        transform: 'scale(0.98) translate(0px, 2px)',
        boxShadow: '3px 3px 10px #FFFFFFAA',
    }
})