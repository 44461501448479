import React from 'react';
import './MonitorAnimation.css'

export default function MonitorAnimation(props) {

    return (
        <>
            <svg
                width={props.width ? `${props.width}px` : "439.23514px"} height={props.width ? `${props.width * 0.6765}px` : "297.15533px"}
                style={{ transform: `scale(${props.scale ? props.scale : '1'})`, zIndex: '10' }}
                viewBox="0 0 439.23514 297.15533"
            >
                <g
                    id="layer1"
                    transform="translate(142.85063,-52.778255)">
                    <path
                        id="rect3061"
                        style={{ fill: '#d4d5d9', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="m 32.385376,53.278255 h 87.714804 c 2.02211,0 3.65001,1.683839 3.65001,3.775424 0,2.091585 -1.6279,3.775424 -3.65001,3.775424 H 32.385376 c -2.022108,0 -3.650015,-1.683839 -3.650015,-3.775424 0,-2.091585 1.627907,-3.775424 3.650015,-3.775424 z" />
                    <path
                        id="rect2987-1"
                        style={{ fill: '#d2d0d0', fillOpacity: 1, stroke: '#d2d0d0', strokeWidth: 0.999997, strokeLinecap: 'round', strokeLinejoin: 'round', strokeOpacity: 1, paintOrder: 'stroke fill markers' }}
                        d="m -108.08243,60.942055 c 0,0 118.80099,-3.260509 178.21676,-3.301695 62.11942,-0.04306 186.33095,3.193618 186.33095,3.193618 24.68243,-0.789516 37.40742,12.999714 37.76215,29.082623 0,0 1.70019,80.244359 1.65625,120.372209 -0.0471,43.0183 -2.05818,129.03858 -2.05818,129.03858 -0.13875,8.69902 -8.15147,10.1095 -10.52241,10.10621 l -415.94965,-0.57751 c -2.12196,-0.003 -6.93907,0.23621 -8.00546,-8.29716 0,0 -1.74765,-88.87275 -1.69755,-133.31448 0.0439,-38.93254 1.7504,-116.784566 1.7504,-116.784566 0.35737,-10.530236 3.8779,-28.475639 32.51674,-29.517829 z"
                    />
                    <path
                        id="rect2987"
                        style={{ fill: '#d2d0cc', fillOpacity: 1, stroke: '#000000', strokeWidth: 0.5, strokeLinecap: 'round', strokeLinejoin: 'round', strokeDasharray: 'none', paintOrder: 'stroke fill markers' }}
                        d="m -111.62888,71.095982 c 0,0 119.78636,-2.93062 179.69159,-2.942802 62.39259,-0.01269 187.15391,2.988878 187.15391,2.988878 22.4111,-0.297226 29.09437,7.157067 29.05547,22.212515 l 1.03738,115.114847 -2.09922,127.94943 c -0.0217,1.32474 -1.06663,2.39281 -2.39154,2.39154 l -409.77877,-0.39327 c -1.32492,-0.001 -2.39266,-1.06663 -2.39155,-2.39154 l -1.02655,-102.19336 1.22762,-137.319157 c 0.0882,-9.86044 -0.50398,-23.705584 19.52166,-25.417081 z"
                    />
                    <path
                        id="rect1415"
                        style={{ strokeWidth: 4, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="M -79.10437,108.86012 H 193.36557 V 309.36438 H -79.10437 Z" />
                    <path
                        id="rect1419"
                        style={{ fill: '#9b9998', strokeWidth: 4, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="m 194.06746,108.33652 h 84.63241 v 148.5 h -84.63241 z" />
                    <path
                        id="rect1421"
                        style={{ fill: '#9b9998', fillOpacity: 1, stroke: 'none', strokeWidth: 4, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="m 194.06759,258.72436 v 50.96795 h 15.11019 a 22.5,22.5 0 0 1 -0.24443,-3.14141 22.5,22.5 0 0 1 22.49992,-22.49992 22.5,22.5 0 0 1 22.49991,22.49992 22.5,22.5 0 0 1 -0.24442,3.14141 h 24.85378 v -50.96795 z" />
                    <path
                        id="path1475-4"
                        style={{ fill: '#9b9b9b', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="m 252.95097,306.4902 a 21.5,21.5 0 0 1 -21.5,21.5 21.5,21.5 0 0 1 -21.5,-21.5 21.5,21.5 0 0 1 21.5,-21.5 21.5,21.5 0 0 1 21.5,21.5 z" />
                    <path
                        id="rect1417-6"
                        style={{ fill: 'none', stroke: '#000000', strokeWidth: 0.5, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="m -77.186027,315.22882 h 22.914303 c 1.324914,0 2.391542,1.06663 2.391542,2.39154 v 13.40376 c 0,1.32491 -1.066628,2.39154 -2.391542,2.39154 h -22.914303 c -1.324914,0 -2.391541,-1.06663 -2.391541,-2.39154 v -13.40376 c 0,-1.32491 1.066627,-2.39154 2.391541,-2.39154 z" />
                    <g
                        id="g19323">
                        <path
                            id="rect1417"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 243.7097,124.45596 h 22.91431 c 1.32491,0 2.39154,1.06663 2.39154,2.39154 v 13.40376 c 0,1.32491 -1.06663,2.39154 -2.39154,2.39154 H 243.7097 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 V 126.8475 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-5"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 243.7097,152.04179 h 22.91431 c 1.32491,0 2.39154,1.06663 2.39154,2.39155 v 13.40375 c 0,1.32491 -1.06663,2.39154 -2.39154,2.39154 H 243.7097 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40375 c 0,-1.32492 1.06663,-2.39155 2.39154,-2.39155 z" />
                        <path
                            id="rect1417-58"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 206.04915,151.99895 h 22.9143 c 1.32492,0 2.39154,1.06662 2.39154,2.39154 v 13.40375 c 0,1.32492 -1.06662,2.39155 -2.39154,2.39155 h -22.9143 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39155 v -13.40375 c 0,-1.32492 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-3"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 206.04915,179.57094 h 22.9143 c 1.32492,0 2.39154,1.06663 2.39154,2.39154 v 13.40375 c 0,1.32492 -1.06662,2.39155 -2.39154,2.39155 h -22.9143 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39155 v -13.40375 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-1"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 243.7097,179.57092 h 22.91431 c 1.32491,0 2.39154,1.06663 2.39154,2.39154 v 13.40376 c 0,1.32491 -1.06663,2.39154 -2.39154,2.39154 H 243.7097 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40376 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-52"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 243.7097,206.98718 h 22.91431 c 1.32491,0 2.39154,1.06663 2.39154,2.39154 v 13.40376 c 0,1.32491 -1.06663,2.39154 -2.39154,2.39154 H 243.7097 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40376 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-2"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 206.04915,206.93636 h 22.9143 c 1.32492,0 2.39154,1.06662 2.39154,2.39154 v 13.40375 c 0,1.32492 -1.06662,2.39154 -2.39154,2.39154 h -22.9143 c -1.32491,0 -2.39154,-1.06662 -2.39154,-2.39154 V 209.3279 c 0,-1.32492 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-7"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 206.04915,234.22694 h 22.9143 c 1.32492,0 2.39154,1.06663 2.39154,2.39155 v 13.40375 c 0,1.32491 -1.06662,2.39154 -2.39154,2.39154 h -22.9143 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40375 c 0,-1.32492 1.06663,-2.39155 2.39154,-2.39155 z" />
                        <path
                            id="rect1417-0"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 206.04915,261.72885 h 22.9143 c 1.32492,0 2.39154,1.06663 2.39154,2.39154 v 13.40376 c 0,1.32491 -1.06662,2.39154 -2.39154,2.39154 h -22.9143 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40376 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-9"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 243.7097,234.35643 h 22.91431 c 1.32491,0 2.39154,1.06663 2.39154,2.39154 v 13.40376 c 0,1.32491 -1.06663,2.39154 -2.39154,2.39154 H 243.7097 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40376 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                        <path
                            id="rect1417-4"
                            fill="#c7c9c6"
                            stroke="#000000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            paintOrder="stroke fill markers"
                            d="m 243.7097,261.7225 h 22.91431 c 1.32491,0 2.39154,1.06663 2.39154,2.39155 v 13.40375 c 0,1.32491 -1.06663,2.39154 -2.39154,2.39154 H 243.7097 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40375 c 0,-1.32492 1.06663,-2.39155 2.39154,-2.39155 z" />
                    </g>
                    <path
                        id="rect1417-21"
                        style={{ fill: '#dccb00', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }}
                        d="m 206.04915,124.41255 h 22.9143 c 1.32492,0 2.39154,1.06663 2.39154,2.39154 v 13.40376 c 0,1.32491 -1.06662,2.39154 -2.39154,2.39154 h -22.9143 c -1.32491,0 -2.39154,-1.06663 -2.39154,-2.39154 v -13.40376 c 0,-1.32491 1.06663,-2.39154 2.39154,-2.39154 z" />
                    <path
                        style={{
                            stroke: "#06ff00",
                            strokeWidth: 1.25,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeOpacity: 1,
                        }}
                        d="m -67.186409,139.55995 2.185318,0.0465 c 1.619532,-4.82184 3.191408,-10.13697 6.256009,0 1.270268,2.96208 4.281581,0.0457 6.470256,-0.0929 1.24101,-2.73489 2.447059,-6.01618 4.242089,-0.0931 l 2.74236,0.13952 0.685591,1.30206 1.114084,-15.99677 1.542577,16.50829 0.385645,-1.30206 4.07069,-0.27901 c 2.90346,-8.72117 5.09342,-7.37608 6.855899,0 0.260723,2.86154 4.00322,0.44527 6.598796,-0.37204 1.446584,-2.64161 2.269047,-4.9301 4.242093,0.1395 l 2.519863,0.114 0.565295,0.78185 0.08335,-1.62981 1.287835,-13.49568 1.585426,16.22928 0.899833,-1.58107 3.4707964,-0.093 c 3.5480693,-9.40314 5.3405651,-6.2459 6.7273646,-0.18599 0.6044646,2.43249 3.87184,0.209 5.8274973,0.279 2.2056235,-3.16288 3.586463,-5.17513 5.5275747,-0.1395 l 2.228188,-0.37201 0.385628,0.83704 1.285491,-15.11325 1.756834,16.3688 0.685582,-1.53459 3.770738,-0.0931 c 2.113164,-6.33483 4.299742,-9.7382 6.813035,-0.0929 0.937826,2.57288 3.991005,-0.13437 5.998939,-0.23251 1.539054,-3.46942 3.232618,-4.33979 5.22762,-0.1395 h 2.356701 l 0.257114,0.69751 1.328334,-14.3227 1.456869,15.81078 0.685593,-1.72059 3.942149,-0.093 c 2.828142,-7.64296 5.027555,-7.65624 6.598784,-0.0465 0.770284,2.83882 4.152444,0.009 6.341735,-0.23252 1.333509,-2.63668 2.691436,-4.94469 4.584868,-0.0465 l 2.742361,-0.0931 0.171388,0.65104 1.799687,-14.32272 1.371175,15.15977 0.599878,-1.11608 3.77074,0.093 c 3.673878,-10.17662 5.363411,-5.27867 7.027317,-0.186 0.108084,1.757 2.673578,1.00396 5.484734,0 1.687266,-2.14172 3.329109,-4.97375 5.4847,0 h 2.570953 l 0.514205,0.83701 1.199788,-15.25273 1.298545,16.29969 0.605979,-1.8414 h 3.938895 c 2.65909,-7.40477 4.95538,-8.36642 6.72639,0 0.33918,2.96946 4.32265,-0.0878 6.48397,-0.13152 1.67641,-4.09445 3.23205,-4.12604 4.66606,-0.0658 l 2.45423,-2e-5 0.2727,1.15087 1.33316,-15.81628 1.66644,16.60546 0.69688,-1.4797 4.09038,-0.29594 c 1.91081,-4.3389 3.7065,-10.95141 6.3931,0.0329" />
                    <path
                        className="drawEcg"
                        style={{
                            stroke: "#000",
                            strokeWidth: 1.25,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeOpacity: 1
                        }}
                        d="m -67.186409,139.55995 2.185318,0.0465 c 1.619532,-4.82184 3.191408,-10.13697 6.256009,0 1.270268,2.96208 4.281581,0.0457 6.470256,-0.0929 1.24101,-2.73489 2.447059,-6.01618 4.242089,-0.0931 l 2.74236,0.13952 0.685591,1.30206 1.114084,-15.99677 1.542577,16.50829 0.385645,-1.30206 4.07069,-0.27901 c 2.90346,-8.72117 5.09342,-7.37608 6.855899,0 0.260723,2.86154 4.00322,0.44527 6.598796,-0.37204 1.446584,-2.64161 2.269047,-4.9301 4.242093,0.1395 l 2.519863,0.114 0.565295,0.78185 0.08335,-1.62981 1.287835,-13.49568 1.585426,16.22928 0.899833,-1.58107 3.4707964,-0.093 c 3.5480693,-9.40314 5.3405651,-6.2459 6.7273646,-0.18599 0.6044646,2.43249 3.87184,0.209 5.8274973,0.279 2.2056235,-3.16288 3.586463,-5.17513 5.5275747,-0.1395 l 2.228188,-0.37201 0.385628,0.83704 1.285491,-15.11325 1.756834,16.3688 0.685582,-1.53459 3.770738,-0.0931 c 2.113164,-6.33483 4.299742,-9.7382 6.813035,-0.0929 0.937826,2.57288 3.991005,-0.13437 5.998939,-0.23251 1.539054,-3.46942 3.232618,-4.33979 5.22762,-0.1395 h 2.356701 l 0.257114,0.69751 1.328334,-14.3227 1.456869,15.81078 0.685593,-1.72059 3.942149,-0.093 c 2.828142,-7.64296 5.027555,-7.65624 6.598784,-0.0465 0.770284,2.83882 4.152444,0.009 6.341735,-0.23252 1.333509,-2.63668 2.691436,-4.94469 4.584868,-0.0465 l 2.742361,-0.0931 0.171388,0.65104 1.799687,-14.32272 1.371175,15.15977 0.599878,-1.11608 3.77074,0.093 c 3.673878,-10.17662 5.363411,-5.27867 7.027317,-0.186 0.108084,1.757 2.673578,1.00396 5.484734,0 1.687266,-2.14172 3.329109,-4.97375 5.4847,0 h 2.570953 l 0.514205,0.83701 1.199788,-15.25273 1.298545,16.29969 0.605979,-1.8414 h 3.938895 c 2.65909,-7.40477 4.95538,-8.36642 6.72639,0 0.33918,2.96946 4.32265,-0.0878 6.48397,-0.13152 1.67641,-4.09445 3.23205,-4.12604 4.66606,-0.0658 l 2.45423,-2e-5 0.2727,1.15087 1.33316,-15.81628 1.66644,16.60546 0.69688,-1.4797 4.09038,-0.29594 c 1.91081,-4.3389 3.7065,-10.95141 6.3931,0.0329" />
                    <g id="g105898">
                        <path
                            style={{
                                fill: 'none',
                                fillOpacity: 1,
                                stroke: '#06ff00',
                                strokeWidth: 1,
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                                strokeDasharray: 'none',
                                strokeOpacity: 1,
                            }}
                            d="m -67.352498,163.63945 2.185318,0.0465 c 1.619532,-4.82184 3.191408,-10.13697 6.256009,0 1.270268,2.96208 4.281581,0.0457 6.470256,-0.0929 1.24101,-2.73489 2.447059,-6.01618 4.242089,-0.0931 l 2.74236,0.13952 0.685591,1.30206 1.114084,-15.99677 1.542577,16.50829 0.385645,-1.30206 4.07069,-0.27901 c 2.90346,-8.72117 5.09342,-7.37608 6.855899,0 0.260723,2.86154 4.00322,0.44527 6.598796,-0.37204 1.446584,-2.64161 2.269047,-4.9301 4.242093,0.1395 l 2.519863,0.114 0.565295,0.78185 0.08335,-1.62981 1.287835,-13.49568 1.585426,16.22928 0.899833,-1.58107 3.4707967,-0.093 c 3.548069,-9.40314 5.3405648,-6.2459 6.7273643,-0.18599 0.6044646,2.43249 3.87184,0.209 5.8274973,0.279 2.2056235,-3.16288 3.586463,-5.17513 5.5275747,-0.1395 l 2.228188,-0.37201 0.385628,0.83704 1.285491,-15.11325 1.756834,16.3688 0.685582,-1.53459 3.770738,-0.0931 c 2.113164,-6.33483 4.299742,-9.7382 6.813035,-0.0929 0.937826,2.57288 3.991005,-0.13437 5.998939,-0.23251 1.539054,-3.46942 3.232618,-4.33979 5.22762,-0.1395 H 39.0425 l 0.257114,0.69751 1.328334,-14.3227 1.456869,15.81078 0.685593,-1.72059 3.942149,-0.093 c 2.828142,-7.64296 5.027555,-7.65624 6.598784,-0.0465 0.770284,2.83882 4.152444,0.009 6.341735,-0.23252 1.333509,-2.63668 2.691436,-4.94469 4.584868,-0.0465 l 2.742361,-0.0931 0.171388,0.65104 1.799687,-14.32272 1.371175,15.15977 0.599878,-1.11608 3.77074,0.093 c 3.673878,-10.17662 5.363411,-5.27867 7.027317,-0.186 0.108084,1.757 2.673578,1.00396 5.484734,0 1.687266,-2.14172 3.329109,-4.97375 5.4847,0 h 2.570953 l 0.514205,0.83701 1.199788,-15.25273 1.298545,16.29969 0.605979,-1.8414 h 3.938894 c 2.6591,-7.40477 4.95539,-8.36642 6.7264,0 0.33918,2.96946 4.32265,-0.0878 6.48397,-0.13152 1.67641,-4.09445 3.23205,-4.12604 4.66606,-0.0658 l 2.45423,-2e-5 0.2727,1.15087 1.33316,-15.81628 1.66644,16.60546 0.69688,-1.4797 4.09038,-0.29594 c 1.91081,-4.3389 3.7065,-10.95141 6.3931,0.0329"
                            id="path19418-6" /><path
                            style={{
                                fill: 'none',
                                stroke: '#ffffff',
                                strokeWidth: 0.264583,
                                strokeLinecap: 'butt',
                                strokeLinejoin: 'miter',
                                strokeOpacity: 1,
                            }}
                            d="m -67.811411,142.27739 h -3.515445 v -13.08104 l 3.349358,0.11123"
                            id="path66406" /><path
                            style={{
                                fill: 'none',
                                stroke: '#ffffff',
                                strokeWidth: 0.264583,
                                strokeLinecap: 'butt',
                                strokeLinejoin: 'miter',
                                strokeOpacity: 1,
                            }}
                            d="m -67.500765,166.32859 h -3.515445 v -13.08104 l 3.349358,0.11123"
                            id="path66406-7" />
                    </g>
                </g>

                <g id="g105880">
                    <g id="text44417">
                        <path style={{ fill: "#1aff00" }} id="path99999"
                            d="m 306.48809,90.726626 q -1.90169,0 -3.34863,-0.689019 -1.43316,-0.689018 -2.21864,-1.943032 -0.78548,-1.267794 -0.78548,-2.935219 v -0.330729 q 0,-1.50206 0.82682,-2.824976 0.82683,-1.322916 2.20486,-1.998154 -1.18511,-0.578776 -1.90169,-1.653644 -0.71658,-1.074869 -0.71658,-2.287542 v -0.523654 q 0,-1.570963 0.73036,-2.756074 0.74414,-1.198893 2.08084,-1.84657 1.35047,-0.661458 3.12814,-0.661458 1.77767,0 3.11437,0.661458 1.35047,0.647677 2.08083,1.84657 0.74414,1.185111 0.74414,2.756074 v 0.523654 q 0,1.240233 -0.73036,2.315102 -0.73036,1.061089 -1.92925,1.626084 1.39182,0.689019 2.23242,2.011934 0.8406,1.309136 0.8406,2.811196 v 0.330729 q 0,1.667425 -0.78548,2.935219 -0.78548,1.254014 -2.23242,1.943032 -1.43316,0.689019 -3.33485,0.689019 z m 0,-3.638018 q 0.75792,0 1.3367,-0.303168 0.59255,-0.316949 0.9095,-0.881944 0.33073,-0.564995 0.33073,-1.295355 v -0.192925 q 0,-0.675238 -0.33073,-1.185112 -0.31695,-0.509874 -0.89572,-0.785481 -0.57878,-0.289388 -1.35048,-0.289388 -0.7717,0 -1.35047,0.289388 -0.57878,0.275607 -0.90951,0.799261 -0.31695,0.509874 -0.31695,1.185112 v 0.206706 q 0,0.730359 0.31695,1.295355 0.33073,0.551215 0.90951,0.854383 0.59255,0.303168 1.35047,0.303168 z m 0,-8.598952 q 0.64768,0 1.12999,-0.275607 0.4961,-0.289388 0.75792,-0.799262 0.27561,-0.523654 0.27561,-1.198892 V 76.02297 q 0,-0.633897 -0.27561,-1.10243 -0.26182,-0.482313 -0.75792,-0.74414 -0.48231,-0.261827 -1.12999,-0.261827 -0.64768,0 -1.14377,0.261827 -0.48231,0.261827 -0.75792,0.74414 -0.26183,0.482313 -0.26183,1.11621 v 0.206706 q 0,0.661458 0.26183,1.171331 0.27561,0.509874 0.75792,0.799262 0.49609,0.275607 1.14377,0.275607 z" />
                        <path style={{ fill: "#1aff00" }} id="path100001"
                            d="m 322.2115,90.726626 q -2.93522,0 -4.46484,-1.502061 -1.52963,-1.51584 -1.52963,-4.189232 v -9.108826 q 0,-2.700953 1.52963,-4.189233 1.5434,-1.50206 4.46484,-1.50206 2.93521,0 4.46484,1.48828 1.52962,1.48828 1.52962,4.203013 v 9.108826 q 0,2.700952 -1.5434,4.203013 -1.52963,1.48828 -4.45106,1.48828 z m 0,-3.638018 q 1.14377,0 1.6812,-0.771701 0.53744,-0.785481 0.53744,-2.2462 v -7.179574 q 0,-1.460719 -0.53744,-2.23242 -0.53743,-0.785481 -1.6812,-0.785481 -1.14378,0 -1.68121,0.785481 -0.53743,0.771701 -0.53743,2.23242 v 7.179574 q 0,1.460719 0.53743,2.2462 0.53743,0.771701 1.68121,0.771701 z" />
                    </g>
                    <g id="text47379">
                        <path style={{ fill: "#1aff00" }} id="path100004"
                            d="m 285.48378,67.708423 h 1.13171 v 7.51373 h -1.13171 z m -2.38745,0 h 1.13171 v 7.51373 h -1.13171 z m 0.63045,3.276276 h 2.41328 v 1.069698 h -2.41328 z" />
                        <path style={{ fill: "#1aff00" }} id="path100006"
                            d="m 288.10893,70.964028 h 1.36942 q 0.21187,0 0.3669,-0.07751 0.15503,-0.08268 0.23255,-0.227376 0.0827,-0.149861 0.0827,-0.361734 v -0.800982 q 0,-0.222208 -0.0827,-0.382404 -0.0775,-0.165364 -0.23255,-0.248046 -0.15503,-0.08268 -0.3669,-0.08268 h -1.36942 v -1.080034 h 1.28674 q 0.60461,0 1.03352,0.201537 0.42892,0.19637 0.65629,0.583942 0.22738,0.382404 0.22738,0.919838 v 0.878496 q 0,0.552936 -0.22738,0.950843 -0.22737,0.39274 -0.66145,0.599445 -0.42892,0.206705 -1.02836,0.206705 h -1.28674 z m -0.51676,-3.260772 h 1.1162 v 7.518897 h -1.1162 z m 1.68464,4.108263 1.04903,-0.237711 1.19889,3.648345 H 290.3 Z" />
                    </g>
                    <g id="text44417-4">
                        <path style={{ fill: "#FF0001" }} id="path100009"
                            d="m 307.37608,160.79752 h -3.02066 v -12.47953 l -1.9513,0.73863 v -3.20808 l 2.39227,-1.08038 h 2.57969 z" />
                        <path style={{ fill: "#FF0001" }} id="path100011"
                            d="m 315.2695,160.96288 q -1.28985,0 -2.32614,-0.56224 -1.02526,-0.57326 -1.65364,-1.65365 -0.61736,-1.0914 -0.71658,-2.57969 v -0.011 h 3.02066 v 0.011 q 0.0882,0.88195 0.54019,1.37804 0.452,0.48507 1.1796,0.48507 0.79375,0 1.2237,-0.51814 0.44097,-0.51814 0.44097,-1.46623 v -1.34497 q 0,-0.94809 -0.39687,-1.45521 -0.38585,-0.51814 -1.09141,-0.51814 -0.65043,0 -1.11345,0.33073 -0.452,0.33072 -0.62839,0.92604 h -2.82223 v -9.21633 h 8.61 v 2.91042 h -5.58933 v 2.83325 q 0.34175,-0.33073 0.92604,-0.51814 0.59531,-0.19844 1.1796,-0.19844 1.24575,0 2.1277,0.57326 0.89296,0.57327 1.35599,1.6757 0.46302,1.10243 0.46302,2.65686 v 1.34497 q 0,1.55442 -0.56224,2.65686 -0.55122,1.10243 -1.60955,1.68672 -1.05834,0.57326 -2.55764,0.57326 z" />
                        <path style={{ fill: "#FF0001" }} id="path100013"
                            d="m 331.58548,146.77458 -3.59392,14.02294 h -3.27422 l 3.48368,-13.11894 h -2.75608 v 2.41432 h -3.02066 v -5.32474 h 9.1612 z" />
                    </g>
                    <g id="text44417-4-7">
                        <path style={{ fill: "#FF0001" }} id="path100016"
                            d="m 320.8781,165.04605 -3.14469,12.27008 h -2.86495 l 3.04823,-11.47908 h -2.41157 v 2.11253 h -2.64309 v -4.65915 h 8.01607 z" />
                        <path style={{ fill: "#FF0001" }} id="path100018"
                            d="m 327.38934,177.46083 q -2.05466,0 -3.12539,-1.05145 -1.07074,-1.06109 -1.07074,-2.93247 v -6.3762 q 0,-1.89067 1.07074,-2.93247 1.08038,-1.05145 3.12539,-1.05145 2.05466,0 3.1254,1.0418 1.07074,1.0418 1.07074,2.94212 v 6.3762 q 0,1.89067 -1.08039,2.94212 -1.07073,1.0418 -3.11575,1.0418 z m 0,-2.54662 q 0.80065,0 1.17685,-0.5402 0.37621,-0.54984 0.37621,-1.57234 v -5.02572 q 0,-1.0225 -0.37621,-1.5627 -0.3762,-0.54984 -1.17685,-0.54984 -0.80064,0 -1.17684,0.54984 -0.37621,0.5402 -0.37621,1.5627 v 5.02572 q 0,1.0225 0.37621,1.57234 0.3762,0.5402 1.17684,0.5402 z" />
                    </g>
                    <g id="text47379-5">
                        <path style={{ fill: "#FF0001" }} id="path100021"
                            d="m 287.57858,137.98601 h 0.89916 l 1.76733,7.51373 h -1.16271 l -1.0542,-5.45185 -1.04903,5.45185 h -1.16271 z m -0.59945,5.04877 h 2.1394 v 1.05937 h -2.1394 z" />
                        <path style={{ fill: "#FF0001" }} id="path100023"
                            d="m 291.37678,141.24162 h 1.36942 q 0.21188,0 0.36691,-0.0775 0.15502,-0.0827 0.23254,-0.22737 0.0827,-0.14987 0.0827,-0.36174 v -0.80098 q 0,-0.22221 -0.0827,-0.3824 -0.0775,-0.16537 -0.23254,-0.24805 -0.15503,-0.0827 -0.36691,-0.0827 h -1.36942 v -1.08004 h 1.28674 q 0.60461,0 1.03353,0.20154 0.42891,0.19637 0.65629,0.58394 0.22737,0.38241 0.22737,0.91984 v 0.8785 q 0,0.55293 -0.22737,0.95084 -0.22738,0.39274 -0.66146,0.59945 -0.42891,0.2067 -1.02836,0.2067 h -1.28674 z m -0.51676,-3.26078 h 1.11621 v 7.5189 h -1.11621 z m 1.68465,4.10827 1.04902,-0.23771 1.19889,3.64834 h -1.22472 z" />
                        <path style={{ fill: "#FF0001" }} id="path100025"
                            d="m 296.41005,138.60613 h 1.13171 v 6.89361 h -1.13171 z m -1.28674,-0.62012 h 3.70519 v 1.08004 h -3.70519 z" />
                    </g>
                    <g id="text44417-4-8">
                        <path style={{ fill: "#00faff" }} id="path100028"
                            d="m 318.07311,208.93593 h -3.02067 V 196.4564 l -1.9513,0.73863 v -3.20808 l 2.39228,-1.08038 h 2.57969 z" />
                        <path style={{ fill: "#00faff" }} id="path100030"
                            d="m 326.187,209.10129 q -1.52135,0 -2.67891,-0.55121 -1.14652,-0.55122 -1.77491,-1.55443 -0.62839,-1.01424 -0.62839,-2.34818 v -0.26458 q 0,-1.20166 0.66146,-2.25999 0.66146,-1.05833 1.76389,-1.59853 -0.94809,-0.46302 -1.52135,-1.32291 -0.57327,-0.8599 -0.57327,-1.83004 v -0.41892 q 0,-1.25678 0.58429,-2.20487 0.59531,-0.95911 1.66467,-1.47726 1.08039,-0.52916 2.50252,-0.52916 1.42214,0 2.4915,0.52916 1.08038,0.51815 1.66467,1.47726 0.59531,0.94809 0.59531,2.20487 v 0.41892 q 0,0.99219 -0.58428,1.85209 -0.58429,0.84887 -1.54341,1.30086 1.11346,0.55122 1.78594,1.60955 0.67248,1.04731 0.67248,2.24897 v 0.26458 q 0,1.33394 -0.62838,2.34818 -0.62839,1.00321 -1.78594,1.55443 -1.14653,0.55121 -2.66789,0.55121 z m 0,-2.91042 q 0.60634,0 1.06936,-0.24253 0.47405,-0.25356 0.72761,-0.70556 0.26458,-0.45199 0.26458,-1.03628 v -0.15434 q 0,-0.5402 -0.26458,-0.9481 -0.25356,-0.40789 -0.71658,-0.62838 -0.46302,-0.23151 -1.08039,-0.23151 -0.61736,0 -1.08038,0.23151 -0.46302,0.22049 -0.7276,0.63941 -0.25356,0.4079 -0.25356,0.94809 v 0.16537 q 0,0.58428 0.25356,1.03628 0.26458,0.44097 0.7276,0.68351 0.47405,0.24253 1.08038,0.24253 z m 0,-6.87917 q 0.51815,0 0.904,-0.22049 0.39687,-0.23151 0.60633,-0.63941 0.22049,-0.41892 0.22049,-0.95911 v -0.15434 q 0,-0.50712 -0.22049,-0.88195 -0.20946,-0.38585 -0.60633,-0.59531 -0.38585,-0.20946 -0.904,-0.20946 -0.51814,0 -0.91501,0.20946 -0.38586,0.20946 -0.60634,0.59531 -0.20946,0.38585 -0.20946,0.89297 v 0.16537 q 0,0.52916 0.20946,0.93706 0.22048,0.4079 0.60634,0.63941 0.39687,0.22049 0.91501,0.22049 z" />
                    </g>
                    <g id="text47379-5-9">
                        <path style={{ fill: "#00faff" }} id="path100033"
                            d="m 287.95065,202.6823 q -0.55811,0 -0.95602,-0.22738 -0.3979,-0.22737 -0.60978,-0.65629 -0.2067,-0.42891 -0.2067,-1.03352 v -3.83438 q 0,-0.60461 0.2067,-1.03353 0.21188,-0.43408 0.60978,-0.65629 0.39791,-0.22737 0.95602,-0.22737 0.56327,0 0.96117,0.22737 0.39791,0.22221 0.60462,0.65629 0.21187,0.42892 0.21187,1.03353 v 0.46508 h -1.14721 v -0.46508 q 0,-0.24805 -0.0723,-0.42375 -0.0724,-0.18086 -0.21704,-0.27388 -0.13953,-0.093 -0.34106,-0.093 -0.19637,0 -0.3359,0.093 -0.13953,0.093 -0.21704,0.27388 -0.0724,0.1757 -0.0724,0.42375 v 3.83438 q 0,0.24804 0.0724,0.42374 0.0775,0.1757 0.21704,0.26872 0.13953,0.093 0.3359,0.093 0.20153,0 0.34106,-0.093 0.14469,-0.093 0.21704,-0.26872 0.0723,-0.1757 0.0723,-0.42891 v -0.45992 h 1.14721 v 0.45992 q 0,0.60461 -0.21187,1.03869 -0.20671,0.42892 -0.60462,0.65629 -0.3979,0.22738 -0.96117,0.22738 z" />
                        <path style={{ fill: "#00faff" }} id="path100035"
                            d="m 290.13655,195.09105 h 1.18856 l 0.85782,5.47769 0.85783,-5.47769 h 1.19372 l -1.44694,7.51373 h -1.20405 z" />
                        <path style={{ fill: "#00faff" }} id="path100037"
                            d="m 295.36619,198.61021 h 1.37459 q 0.18087,0 0.31006,-0.0827 0.12919,-0.0827 0.19637,-0.23771 0.0723,-0.16019 0.0723,-0.37723 v -1.01803 q 0,-0.22737 -0.0672,-0.38757 -0.0672,-0.16536 -0.20153,-0.24804 -0.12919,-0.0879 -0.31006,-0.0879 h -1.37459 v -1.08004 h 1.38493 q 0.5426,0 0.93017,0.20154 0.38757,0.19637 0.58911,0.57877 0.20153,0.38241 0.20153,0.91984 v 1.2299 q 0,0.5271 -0.2067,0.90433 -0.20154,0.37207 -0.58911,0.56844 -0.38757,0.19637 -0.925,0.19637 h -1.38493 z m -0.56844,-3.51916 h 1.13171 v 7.51373 h -1.13171 z" />
                    </g>
                </g>

                <g id="g105887">
                    <path
                        id="path71519"
                        stroke="#00faff"
                        strokeWidth="1.25"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeDasharray="none"
                        strokeOpacity="1"
                        transform="translate(142.85063,-52.778255)"
                        d="m -68.860104,255.64668 c 2.539804,-4.97743 5.324722,-2.99625 8.151027,0.15973 3.639915,-0.53004 3.484247,2.03742 4.665034,3.51422 2.776849,3.34391 3.885198,1.14228 5.280219,-0.1066 1.803885,-1.17944 3.544522,-2.57784 5.741576,-2.39605 2.167726,-1.60294 3.85647,-0.84281 5.48529,0.21298 3.348812,2.45022 6.267263,1.43616 9.073742,-0.4792 4.559004,-7.19406 6.175507,-2.8424 8.40734,-0.90518 4.283895,-0.69053 3.554911,2.68262 5.274249,4.07105 1.750375,2.71104 3.858945,1.62725 6.089891,-0.753 2.1831455,-1.36391 3.4712645,-1.7982 4.603677,-2.07076 3.2661798,-2.373 4.2887439,-0.37666 6.16234118,-0.0376 2.65868492,1.5459 5.55071412,1.96076 9.02608402,-0.4518 4.0768038,-7.47592 5.8172328,-1.60513 8.4098288,-0.60241 3.260015,-1.09261 3.311987,1.87115 4.5674,3.31323 1.639522,2.03727 3.490856,2.99655 6.089879,0.15059 1.937873,-1.84931 3.561193,-1.95619 5.219891,-2.25902 1.892721,-2.13028 3.713265,-1.4871 5.509889,0.0753 3.528502,2.00476 6.791592,1.80412 9.740207,-1.00958 2.192909,-4.38313 4.837725,-4.7297 7.997199,-0.47921 3.708661,-0.48383 3.847045,2.45536 5.280219,4.15315 1.251372,1.13883 2.23354,2.72171 6.664368,-1.38437 1.486659,-0.85526 2.973304,-1.70348 4.459964,-1.9701 1.437124,-0.844 3.038271,-0.59775 4.716313,0.15975 4.364949,3.85236 6.649795,0.86318 9.483896,-0.31948 2.114906,-3.33555 4.332712,-6.21518 8.458594,-0.63895 2.74069,-1.0726 3.88632,2.08866 5.485246,4.04666 2.58631,2.6872 4.12202,1.00959 5.63909,-0.74543 1.78078,-1.32577 3.42775,-1.53968 5.07514,-1.7571 1.74297,-2.01311 3.48595,-1.57746 5.22893,-0.0532 6.29318,2.89602 6.93477,0.11766 9.48389,-0.74545 4.20887,-6.88356 6.06467,-2.70782 8.15102,-0.4792 3.07247,-1.10649 4.14279,1.45989 5.38272,3.40772"
                    />
                    <path
                        id="path56169-0-6"
                        stroke="#ffffff"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="1, 2"
                        strokeDashoffset="0"
                        strokeOpacity="1"
                        fill="none"
                        d="M 70.175891,195.06356 H 276.40462"
                    />
                    <path
                        id="path56169-0-6-4"
                        stroke="#add4ff"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="1, 2"
                        strokeDashoffset="0"
                        strokeOpacity="1"
                        fill="none"
                        d="M 69.861269,224.00847 H 328.6313"
                    />
                    <path
                        id="path56169-6-7"
                        stroke="#ffffff"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="1, 4"
                        strokeDashoffset="0"
                        strokeOpacity="1"
                        fill="none"
                        d="M 70.98411,212.88233 H 277.21284"
                    />
                </g>
                <g id="g105893">
                    <path
                        id="path56169"
                        stroke="#ffffff"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="1, 2"
                        strokeDashoffset="0"
                        strokeOpacity="1"
                        fill="none"
                        d="M 70.967602,131.03422 H 277.19633"
                    />
                    <path
                        id="path56169-0"
                        stroke="#ffffff"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="1, 2"
                        strokeDashoffset="0"
                        strokeOpacity="1"
                        fill="none"
                        d="M 70.967604,175.77285 H 277.19633"
                    />
                    <path
                        id="path56169-6"
                        stroke="#ffffff"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="1, 4"
                        strokeDashoffset="0"
                        strokeOpacity="1"
                        fill="none"
                        d="M 70.967603,153.40353 H 277.19633"
                    />
                    <path
                        id="path34101"
                        stroke="#ff0002"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="none"
                        strokeOpacity="1"
                        fill="none"
                        d="m 75.66415,150.4967 c 2.592733,-19.12453 4.843065,-8.34565 7.11902,0.19489 2.488402,-1.06776 2.845887,3.78583 4.173228,5.94444 1.17097,4.64964 3.425562,6.71829 5.89162,8.28325 2.078283,-0.12016 5.763079,2.70305 5.95295,-0.87704 l 1.350172,-11.98636 c 1.77562,-18.1237 4.1876,-13.2729 7.42588,-1.46175 2.52414,-0.56711 3.28798,3.6175 4.23459,7.30875 1.86402,5.58616 3.93638,5.54887 5.95298,7.0164 1.99012,-0.0535 5.46031,2.83083 5.70748,-0.68214 l 1.2274,-12.37614 c 1.60565,-15.48802 3.59047,-16.66972 6.99629,-1.75411 3.79537,-0.029 3.14747,5.23325 4.6028,7.99089 2.2338,4.84236 4.05176,6.05302 5.83025,6.91893 1.94933,-0.2463 5.45003,3.5648 5.64611,-1.26683 l 1.47291,-12.37614 c 1.52612,-13.45238 3.76858,-17.20643 7.48723,-0.97449 2.79835,-0.35218 3.15436,5.64177 4.72558,8.47813 0.90636,2.67941 2.54106,4.54928 4.60281,5.94445 2.3066,-0.23495 5.22457,4.38415 6.81217,-1.55918 l 1.04331,-11.88891 c 1.52488,-7.4622 2.91872,-19.70392 6.91694,-1.13884 3.23949,1.01969 3.65596,4.83131 5.11172,8.35013 1.70893,5.37774 3.72347,4.93207 5.64615,6.2368 1.96594,-0.23149 5.07758,4.15507 5.70748,-1.46175 l 0.98192,-11.69399 c 1.68046,-8.49445 2.75984,-20.5282 7.2269,-1.76592 4.15763,0.61691 3.09942,5.87184 4.59681,8.8543 1.7442,2.94191 3.49695,5.76833 5.46786,5.65042 2.08409,-0.40711 5.20106,5.19945 6.249,-1.24034 l 1.38865,-12.12771 c 2.62997,-19.54377 4.85122,-7.94592 7.03538,-0.59021 3.39676,-1.16327 3.18885,4.52029 4.84469,7.61877 1.37992,4.60531 3.26526,4.93023 4.95748,6.89074 2.16004,-0.43321 5.09843,3.92283 6.42256,-1.65378 l 1.30188,-12.67897 c 1.35738,-11.068 3.22829,-15.99866 6.76976,-0.96471 3.97296,-0.98071 3.29807,5.8801 4.94711,8.82015 1.29888,5.10869 2.48086,5.62722 5.38107,5.65043 1.78526,-0.0514 4.87645,3.69906 5.33768,-0.20672 l 1.38866,-14.47059 m 0,0 c 2.59273,-19.12452 4.84306,-8.34563 7.11902,0.1949 2.4884,-1.06776 2.84589,3.78583 4.17323,5.94444"
                    />
                    <path
                        className='drawArt'
                        id="path34101"
                        stroke="#000"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeDasharray="none"
                        strokeOpacity="1"
                        fill="none"
                        d="m 75.66415,150.4967 c 2.592733,-19.12453 4.843065,-8.34565 7.11902,0.19489 2.488402,-1.06776 2.845887,3.78583 4.173228,5.94444 1.17097,4.64964 3.425562,6.71829 5.89162,8.28325 2.078283,-0.12016 5.763079,2.70305 5.95295,-0.87704 l 1.350172,-11.98636 c 1.77562,-18.1237 4.1876,-13.2729 7.42588,-1.46175 2.52414,-0.56711 3.28798,3.6175 4.23459,7.30875 1.86402,5.58616 3.93638,5.54887 5.95298,7.0164 1.99012,-0.0535 5.46031,2.83083 5.70748,-0.68214 l 1.2274,-12.37614 c 1.60565,-15.48802 3.59047,-16.66972 6.99629,-1.75411 3.79537,-0.029 3.14747,5.23325 4.6028,7.99089 2.2338,4.84236 4.05176,6.05302 5.83025,6.91893 1.94933,-0.2463 5.45003,3.5648 5.64611,-1.26683 l 1.47291,-12.37614 c 1.52612,-13.45238 3.76858,-17.20643 7.48723,-0.97449 2.79835,-0.35218 3.15436,5.64177 4.72558,8.47813 0.90636,2.67941 2.54106,4.54928 4.60281,5.94445 2.3066,-0.23495 5.22457,4.38415 6.81217,-1.55918 l 1.04331,-11.88891 c 1.52488,-7.4622 2.91872,-19.70392 6.91694,-1.13884 3.23949,1.01969 3.65596,4.83131 5.11172,8.35013 1.70893,5.37774 3.72347,4.93207 5.64615,6.2368 1.96594,-0.23149 5.07758,4.15507 5.70748,-1.46175 l 0.98192,-11.69399 c 1.68046,-8.49445 2.75984,-20.5282 7.2269,-1.76592 4.15763,0.61691 3.09942,5.87184 4.59681,8.8543 1.7442,2.94191 3.49695,5.76833 5.46786,5.65042 2.08409,-0.40711 5.20106,5.19945 6.249,-1.24034 l 1.38865,-12.12771 c 2.62997,-19.54377 4.85122,-7.94592 7.03538,-0.59021 3.39676,-1.16327 3.18885,4.52029 4.84469,7.61877 1.37992,4.60531 3.26526,4.93023 4.95748,6.89074 2.16004,-0.43321 5.09843,3.92283 6.42256,-1.65378 l 1.30188,-12.67897 c 1.35738,-11.068 3.22829,-15.99866 6.76976,-0.96471 3.97296,-0.98071 3.29807,5.8801 4.94711,8.82015 1.29888,5.10869 2.48086,5.62722 5.38107,5.65043 1.78526,-0.0514 4.87645,3.69906 5.33768,-0.20672 l 1.38866,-14.47059 m 0,0 c 2.59273,-19.12452 4.84306,-8.34563 7.11902,0.1949 2.4884,-1.06776 2.84589,3.78583 4.17323,5.94444"
                    />
                </g>

            </svg>

        </>
    );
}

