const materialTheme = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#1976D2',
            light: '#4791DB',
            dark: '#115293',
            contrastText: '#fff'
          },
          secondary: {
            main: '#18C499',
            light: '#38FFCD',
            dark: '#07785C',
            contrastText: '#000000EE'
          },
          error: {
            main: '#F44336',
            light: '#E57373',
            dark: '#D32F2F',
            contrastText: '#fff'
          },
          warning: {
            main: '#FF9800',
            light: '#FFB74D',
            dark: '#F57C00',
            contrastText: '#000000EE'
          },
        info: {
            main: '#C73E7088',
            light: '#FF69A088',
            dark: '#7A1A3D88',
            contrastText: '#fff'
        },
        greenDark: {
            main: '#34950a',
            light: '#4daa17',
            dark: '#007300',
            // contrastText: '#D2F2BA'
            contrastText: '#FFF'
        },
        success: {
            main: '#71D927',
            light: '#99E364',
            dark: '#50991C',
            contrastText: '#000000EE'
          },
          comp: {
            tableBg: '#00001188',
            tableHeaderBg: '#000011FF',
            boxBorder: '#555577',
            boxBg: '#00001188',
            spColor1: '#ffa28b',
            spColor2: '#ab9ff5',
            navbarBg: '#1E2850',
            gray: '#AAAACC',
          },
          background: {
            default: '#0d1117',
            paper: '#161b22',
          },
          text: {
            primary: '#fff',
            secondary: '#FFFFFFBB',
            disabled: '#FFFFFF88',
            hint: '#FFFFFF88',
          },
    },
    shape: {
        borderRadius: 20,
    },
    typography: {
        fontFamily: [
            'Mona Sans',
        ].join(','),
    },
}

export default materialTheme;