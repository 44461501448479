import React, { useEffect, useState } from 'react';

import HTMLFlipBook from "react-pageflip";
import { useLocation } from 'react-router-dom'

function CatalogoMembranas() {
    const location = useLocation();

    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (location.pathname === '/catalogoMembranas') {
            setFlag(true);
        } else {
            setFlag(false);
        }
    }, [location]);

    return (
        <div
            style={{
                display: 'flex',
                background: `${flag ? 'linear-gradient(180deg, rgba(13,17,23,1) 30%, #171726 100%)' : 'transparent'}`,
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: `${flag ? '0px' : '120px'}`,
                transition: 'all 0.5s ease-in-out',
                overflow: 'hidden'
            }}>
            <p style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', color: '#FFF', marginBottom: '0px' }} as='h1'>Catálogo de Membranas</p>
            <div style={{ width: '100%' }}>
                <hr style={{ color: '#FFF', margin: '18px 0px' }}></hr>
            </div>
            <HTMLFlipBook width={794} height={1123} maxShadowOpacity={0.1} minWidth={350} maxWidth={650}
                showCover={true}
                size="stretch"
                drawShadow={false}
                style={{ overflow: 'hidden' }}
                mobileScrollSupport={true}>
                {
                    [...Array(31)].map((el, index) =>
                        <div key={index} style={{ overflow: 'hidden' }}>
                            <picture>
                                <source srcSet={`./catalogo-membranas/${index + 1}.webp`} type='image/webp' />
                                <source srcSet={`./catalogo-membranas/${index + 1}.jpg`} type='image/jpg' />
                                <img src={`./catalogo-membranas/${index + 1}.webp`} alt='img-ctalog' style={{ width: '100%' }} />
                            </picture>
                        </div>
                    )
                }
            </HTMLFlipBook>
        </div>
    );
}

export default CatalogoMembranas;