import { React, useEffect, useState } from 'react';
import { Container, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";

import CatalogoMembranas from '../catalogs/CatalogoMembranas';

import {
    StyledTitle, StyledSubtitle, StyledButton, StyledSecondaryTitle,
    StyledDivImage, StyledDivider, StyledDividerBottom, StyledContent, StyledContent2, StyledDivImage2
} from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";

import MembranasBackground from './MembranasBackground';
import StepperV from './StepperV';
import StepperH from './StepperH';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

const Membranas = () => {

    const [showCatalogo, setShowCatalogo] = useState(false);
    const [index, setIndex] = useState(-1);
    const slides = [
        { src: "./images/membranas/membrana-24.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Monitor de Signos Vitales Drager Infinity Delta', description: 'Teclado de membrana compatible con Monitor de Signos Vitales Drager Infinity Delta' },
        { src: "./images/membranas/membrana-7.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Mesa Quirúrgica Trumpf Saturn Select', description: 'Teclado de membrana compatible con Mesa Quirúrgica Trumpf Saturn Select' },
        { src: "./images/membranas/membrana-16.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Electrocauterio Force FX', description: 'Teclado de membrana compatible con Electrocauterio Force FX' },
        { src: "./images/membranas/membrana-19.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Mesa Quirúrgica Trumpf Saturn (control y panel)', description: 'Teclado de membrana compatible con Mesa Quirúrgica Trumpf Saturn (control y panel)' },
        { src: "./images/membranas/membrana-9.webp", width: 1000, height: 1000, alt: 'Teclado de membrana RX Philips Practix Convenio', description: 'Teclado de membrana compatible con RX Philips Practix Convenio' },
        { src: "./images/membranas/membrana-14.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Mesa Quirúrgica Medifa 6000 Series', description: 'Teclado de membrana compatible con Mesa Quirúrgica Medifa 6000 Series' },
        { src: "./images/membranas/membrana-1.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Esterilizador Tuttnauer Cat2007', description: 'Teclado de membrana compatible con Esterilizador Tuttnauer Cat2007' },
        { src: "./images/membranas/membrana-26.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Electrocauterio Force 2', description: 'Teclado de membrana compatible con Electrocauterio Force 2' },
        { src: "./images/membranas/membrana-28.webp", width: 1000, height: 1000, alt: 'Teclado de membrana Electrocauterio Force 2', description: 'Teclado de membrana compatible con Electrocauterio Force 2' },
        { src: "./images/membranas/membrana-30.webp", width: 1000, height: 1000, alt: 'Membrana (overlay) Ultrasonido GE LOGIQ 200', description: 'Membrana (overlay) compatible con Ultrasonido GE LOGIQ 200' },
    ]

    useEffect(() => {
        AOS.init({ duration: 400 })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        document.title = "Teclados de membrana";
    }, [])

    const handleShowCatalog = (e) => {
        e.preventDefault();
        setShowCatalogo(showCatalogo => !showCatalogo);
    }

    return (
        <>
            <Container maxWidth='xxl' style={{ margin: '0px', padding: '0px' }}>
                <MembranasBackground />
            </Container>
            <Container maxWidth='xxl'>
                <Container maxWidth='xl'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sx={{ textAlign: 'center' }} data-aos='fade-up'>
                            <StyledTitle as='h1' >
                                Teclados de membrana
                            </StyledTitle>
                        </Grid>

                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <Box>
                                <StyledSecondaryTitle data-aos="fade-up" style={{ marginTop: '32px', paddingBottom: '32px' }}>
                                    Los <span>teclados de membrana</span> son elementos de sistemas electrónicos que permiten la <span>interacción</span> entre el usuario y el dispositivo.
                                    Están compuestos de múltiples capas delgadas de <span>polímeros</span> y una o varias capas de <span>circuito conductivo de plata</span>.
                                </StyledSecondaryTitle>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <StyledDivImage>
                                <picture>
                                    <source srcSet='./images/collage-2-membranas.webp' type='image/webp' />
                                    <source srcSet='./images/collage-2-membranas.jpg' type='image/jpg' />
                                    <img src='./images/collage-2-membranas.webp' alt='Ejemplos de teclados de membrana para equipo medico' style={{ marginBottom: '16px' }} />
                                </picture>
                            </StyledDivImage>
                        </Grid>
                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <StyledButton style={{ marginBottom: `${showCatalogo ? '0px' : '56px'}`, marginTop: '68px' }} onClick={(e) => handleShowCatalog(e)}>{showCatalogo ? 'Ocultar' : 'Ver Catálogo'}</StyledButton>
                            {showCatalogo ? <CatalogoMembranas /> : null}
                        </Grid>
                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <Box>
                                <StyledSubtitle style={{ marginBottom: '64px' }}>
                                    Nuestros teclados son fabricados con materiales de <span>alta calidad y tecnología</span>, lo cual garantiza su <span>durabilidad y resistencia</span>.
                                </StyledSubtitle>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} style={{ zIndex: '10' }}>
                            <PhotoAlbum
                                layout="rows"
                                photos={slides}
                                targetRowHeight={200}
                                onClick={({ index: current }) => setIndex(current)}
                            />

                            <Lightbox
                                plugins={[Captions]}
                                captions={{ descriptionTextAlign: 'center' }}
                                index={index}
                                slides={slides}
                                open={index >= 0}
                                close={() => setIndex(-1)}
                            />
                        </Grid>
                    </Grid>

                </Container>
                <Container maxWidth='xl' style={{ marginTop: '128px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <StyledSubtitle as='h2' style={{ fontWeight: '700', fontSize: '38px', lineHeight: '42px', marginBottom: '12px' }}>¿Cómo diferenciar un <span>teclado</span> y una <span>membrana</span>?</StyledSubtitle>
                        </Grid>
                        <Grid item xs={12} md={6} data-aos='fade-up'>
                            <StyledSubtitle style={{ fontWeight: '700', marginTop: '24px' }}>Teclado</StyledSubtitle>
                            <StyledContent data-aos="fade-up">
                                Los <span> teclados </span> de membrana generalmente contienen<span> circuitos electrónicos</span>,
                                luces<span> LED </span> y <span> botones táctiles </span> dentro de múltiples capas de polímeros.
                                El teclado se une a los dispositivos a través de un <span>conector de pines</span>, que puede ser rígido o flexible.
                            </StyledContent>
                            <StyledDivImage2>
                                <picture>
                                    <source srcSet='./images/membranas/explosive-keyboard.webp' type='image/webp' />
                                    <source srcSet='./images/membranas/explosive-keyboard.png' type='image/png' />
                                    <img src='./images/membranas/explosive-keyboard.webp' alt='capas que componen un teclado de membrana' />
                                </picture>
                            </StyledDivImage2>
                        </Grid>
                        <Grid item xs={12} md={6} data-aos='fade-up'>
                            <StyledSubtitle style={{ fontWeight: '700', marginTop: '24px' }}>Membrana</StyledSubtitle>
                            <StyledContent2 data-aos="fade-up">
                                Las <span> membranas </span> a diferencia de los teclados no contienen un circuito electrónico,
                                generalmente están compuestas por <span> dos capas</span>, la capa superior, que contiene la <span> impresión gráfica</span>,
                                y la capa inferior, que es el <span>adhesivo</span> para instalar en el equipo.
                            </StyledContent2>
                            <StyledDivImage2>
                                <picture>
                                    <source srcSet='./images/membranas/explosive-overlay.webp' type='image/webp' />
                                    <source srcSet='./images/membranas/explosive-overlay.png' type='image/png' />
                                    <img src='./images/membranas/explosive-overlay.webp' alt='capas que componen una membrana' />
                                </picture>
                            </StyledDivImage2>
                        </Grid>
                    </Grid>
                </Container>
            </Container>

            <Container maxWidth='xxl' style={{ background: '#1B2133', paddingLeft: '0px', paddingRight: '0px' }} >
                <Container maxWidth='xxl' style={{ background: '#1B2133', paddingLeft: '0px', paddingRight: '0px' }}>
                    <StyledDivider>
                        <img src='./images/section-space-v1.svg' alt='div-img' />
                    </StyledDivider>
                </Container>

                <Container maxWidth='xl' style={{ background: '#1B2133' }}>
                    <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: '32px' }}>
                        <StyledSecondaryTitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px', marginTop: '0px' }} as='h2'>
                            Características de nuestros <span>teclados</span> y <span>membranas</span>
                        </StyledSecondaryTitle>
                        <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid item sm={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                                <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                    <img src='./images/membranas/membrana-31.jpg' alt='impresion uv de alta calidad' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </div>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                    Impresión de alta calidad
                                </StyledSubtitle>
                                {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                    Impresión de gran formato. Alta calidad y precisión.
                                </StyledSubtitle> */}
                            </Grid>
                            <Grid item sm={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                                <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                    <img src='./images/membranas/membrana-23.jpg' alt='corte laser cnc' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </div>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                    Corte de precisión
                                </StyledSubtitle>
                                {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                    Cualquier forma. Procesamiento láser CNC.
                                </StyledSubtitle> */}
                            </Grid>
                            <Grid item sm={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                                <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                    <img src='./images/membranas/membrana-7.jpg' alt='serigrafia de circuito de plata para teclados de membrana' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </div>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                    Circuito electrónico
                                </StyledSubtitle>
                                {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                    Circuito de plata de alta conductividad. Leds SMD. Conectores FFC/FPC.
                                </StyledSubtitle> */}
                            </Grid>
                            <Grid item sm={12} md={6} lg={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                                <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                    <img src='./images/membranas/membrana-26.jpg' alt='embozado de teclados de membrana' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </div>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                    Acabado Superficial
                                </StyledSubtitle>
                                {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                    Poliéster mate o satinado. Barnices estructurales UV. Embozado.
                                </StyledSubtitle> */}
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <StyledSecondaryTitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px', marginTop: '12px' }} as='h2'>
                            Características de nuestros <span>teclados</span> y <span>membranas</span>
                        </StyledSecondaryTitle>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Grid item xs={12} data-aos="fade-up">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px 0px', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                        <img src='./images/membranas/membrana-31.jpg' alt='impresion uv de alta calidad' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                    </div>
                                    <div style={{ marginLeft: '12px', minWidth: '194px' }}>
                                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                            Impresión de alta calidad
                                        </StyledSubtitle>
                                        {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500', marginBottom: '0px' }}>
                                            Impresión de gran formato. Alta calidad y precisión.
                                        </StyledSubtitle> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} data-aos="fade-up">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px 0px', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                        <img src='./images/membranas/membrana-23.jpg' alt='corte laser cnc' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                    </div>
                                    <div style={{ marginLeft: '12px', minWidth: '194px' }}>
                                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                            Corte de precisión
                                        </StyledSubtitle>
                                        {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500', marginBottom: '0px' }}>
                                            Cualquier forma. Procesamiento láser CNC.
                                        </StyledSubtitle> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} data-aos="fade-up">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px 0px', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                        <img src='./images/membranas/membrana-7.jpg' alt='serigrafia de circuito de plata para teclados de membrana' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                    </div>
                                    <div style={{ marginLeft: '12px', minWidth: '194px' }}>
                                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                            Circuito electrónico
                                        </StyledSubtitle>
                                        {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500', marginBottom: '0px' }}>
                                            Circuito de plata de alta conductividad. Leds SMD. Conectores FFC/FPC.
                                        </StyledSubtitle> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} data-aos="fade-up">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px 0px', alignItems: 'center' }}>
                                    <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                        <img src='./images/membranas/membrana-26.jpg' alt='embozado de teclados de membrana' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                    </div>
                                    <div style={{ marginLeft: '12px', minWidth: '194px' }}>
                                        <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                            Acabado Superficial
                                        </StyledSubtitle>
                                        {/* <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500', marginBottom: '0px' }}>
                                            Poliéster mate o satinado. Barnices estructurales UV. Embozado.
                                        </StyledSubtitle> */}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ marginTop: '86px' }} >
                        <StyledSubtitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px' }} data-aos='fade-up'> Acércate con nosotros, <span>somos el fabricante de membranas líder en México</span>,
                            ofrecemos un servicio de calidad a precios competitivos.
                        </StyledSubtitle>
                        <StyledSubtitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px', marginTop: '12px' }} data-aos='fade-up'> ¡Contáctanos ahora!</StyledSubtitle>
                        <Link to='/contacto' style={{ textDecoration: 'none' }}>
                            <StyledButton data-aos='fade-up'>Contacto</StyledButton>
                        </Link>
                    </Box>
                </Container>
                <Container maxWidth='xxl' style={{ paddingLeft: '0px', paddingRight: '0px', background: '#0d1117' }}>
                    <StyledDividerBottom style={{ margin: '32px 0px' }}>
                        <img src='./images/section-space-v2.svg' alt='div-img' />
                    </StyledDividerBottom>
                </Container>
            </Container>
            <Container maxWidth='xxl' style={{ paddingLeft: '0px', paddingRight: '0px', background: 'linear-gradient(180deg, rgba(13,17,23,1) 30%, #171726 100%)', paddingBottom: '64px' }}>
                <Container maxWidth='xl' style={{ marginBottom: '64px' }}>
                    <StyledTitle>
                        Proceso de venta
                    </StyledTitle>
                </Container>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <StepperH />
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <StepperV />
                </Box>
            </Container>
        </>
    );
};

export default Membranas;
