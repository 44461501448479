import React, { useState, useEffect } from 'react';
import { Container, Grid, Tooltip } from '@mui/material';
import { StyledSecondaryTitle, StyledSubtitle, StyledTitle, StyledDivider, StyledRowTextImg2 } from './StyledComponents';
import AOS from 'aos';
import "aos/dist/aos.css";

import { Link } from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import JuvalfriAnimation from '../home/JuvalfriAnimation';
import MonitorAnimation from '../home/MonitorAnimation';
import Maps from './Maps';

const Contacto = () => {

    const [apiRes, setApiRes] = useState({ show: false, message: '', severity: '' });

    useEffect(() => {
        AOS.init({ duration: 400 })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        document.title = "Contacto con Juvalfri";
    }, [])

    const handleCopyEmail = async () => {
        await navigator.clipboard.writeText('comercializadorajuvalfri@gmail.com');
        setApiRes(state => { return { ...state, show: true, message: 'Correo copiado al portapapeles', severity: 'success' } });
    }

    const handleCloseMessage = (show) => {
        setApiRes(state => { return { ...state, show: show } });
    }

    return (
        <>
            <Container maxWidth='xxl' style={{ background: 'linear-gradient(180deg, rgba(13,17,23,1) 30%, #171726 100%)', paddingLeft: '0px', paddingRight: '0px' }}>
                <Container maxWidth='lg'>
                    <StyledTitle as="h1">Contacto con Juvalfri</StyledTitle>
                    <StyledSecondaryTitle as="h2">¿Te interesa algún producto o servicio?</StyledSecondaryTitle>
                    <StyledRowTextImg2>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                <StyledSubtitle>¿Estás interesado en un <span>producto</span> o <span>servicio</span>,
                                    necesitas <span>asesoramiento</span> o deseas recibir una <span>oferta</span> o <span>cotización</span>?</StyledSubtitle>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2} className='img-container'>
                                <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
                                    <div style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '50%', background: 'radial-gradient(227.48% 373.86% at 50% 220.45%, #c178e6 31.77%, #0fb6db 100%)' }}>
                                        <img src='./images/icon-users-2.svg' alt='img' style={{ width: '100%' }}></img>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </StyledRowTextImg2>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledSecondaryTitle>Contáctanos a través de <span style={{ color: '#79ff89' }}>WhatsApp</span></StyledSecondaryTitle>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} className='zoomIcon whatsLink'>
                                    <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', padding: '0px 16px 0px 16px', marginBottom: '0px' }}>
                                        <WhatsAppIcon fontSize='medium' />
                                    </p>
                                    <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', marginBottom: '0px', color: '#FFF' }}>
                                        55-48-61-73-32
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Link to='https://wa.me/message/7XOWX4XGRUGWO1' target="_blank" style={{ textDecoration: 'unde', color: 'inherit' }}>
                                    <Tooltip title='Envíar WhatsApp a Juvalfri' placement='right'>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} className='zoomIcon whatsLink'>
                                            <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', marginBottom: '0px', padding: '0px 16px 0px 16px', textDecoration: 'underline' }}>
                                                Iniciar Chat
                                            </p>
                                        </div>
                                    </Tooltip>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledSecondaryTitle>Envíanos un <span style={{ color: '#80b1ff' }}>correo electrónico</span></StyledSecondaryTitle>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} onClick={() => handleCopyEmail()} className='zoomIcon mailLink'>
                                    <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', padding: '0px 16px 0px 16px', marginBottom: '0px' }}>
                                        <EmailIcon fontSize='medium' />
                                    </p>
                                    <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', marginBottom: '0px', color: '#FFF' }}>
                                        comercializadorajuvalfri@gmail.com
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledSecondaryTitle>Puedes <span style={{ color: '#fbbc04' }}>encontrarnos</span> en: </StyledSecondaryTitle>
                            <Maps />
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} className='zoomIcon mapsLink'>
                                    <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', padding: '0px 16px 0px 16px' }}>
                                        <PlaceIcon fontSize='medium' />
                                    </p>
                                    <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', color: '#FFF' }}>
                                        Niza 19 N, San Álvaro, Azcapotzalco, 02090, CDMX, México.
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Link to='https://maps.app.goo.gl/LEURbR4gVu4ytxVu9' target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Tooltip title='Ver direccion en Google Maps'>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} className='zoomIcon mapsLink'>
                                            <p style={{ textAlign: 'start', fontWeight: '400', fontSize: '18px', padding: '0px 16px 0px 16px', textDecoration: 'underline' }}>
                                                Ver en Google Maps
                                            </p>
                                        </div>
                                    </Tooltip>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTitle as="h1">Visita nuestras redes sociales</StyledTitle>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to='https://www.facebook.com/Juvalfri' target="_blank">
                                <img src="./images/Facebook.png" style={{ maxWidth: '100px', height: 'auto', padding: '8px' }} className='zoomIcon' />
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to='https://www.instagram.com/juvalfri/' target="_blank">
                                <img src="./images/Instagram.png" style={{ maxWidth: '100px', height: 'auto', padding: '8px' }} className='zoomIcon' />
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to='https://www.linkedin.com/company/comercializadora-de-servicios-integrales-juvalfri-s-a-de-c-v/' target="_blank">
                                <img src="./images/LinkedIn.png" style={{ maxWidth: '100px', height: 'auto', padding: '8px' }} className='zoomIcon' />
                            </Link>
                        </Grid>
                        <Grid item xs={3}>
                            <Link to='https://x.com/juvalfri' target="_blank">
                                <img src="./images/X.png" style={{ maxWidth: '100px', height: 'auto', padding: '8px' }} className='zoomIcon' />
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '128px 0px 0px 0px' }}>
                                <JuvalfriAnimation />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <CustomMessage open={apiRes.show} message={apiRes.message} severity={apiRes.severity} handleCloseMessage={handleCloseMessage} />
            </Container>
        </>
    )
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomMessage = (props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.handleCloseMessage(false);
        setOpen(false);
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
                    {props.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}


export default Contacto;