import { useState } from "react";
import {
    IconButton, Button, Box, Menu, MenuItem, ListItem, Container, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import JuvalfriAnimation from "../home/JuvalfriAnimation";

import styled from 'styled-components'
import { Link, useNavigate } from "react-router-dom";

const ButtonMenu = styled(Button)({
    textTransform: 'none !important',
    fontSize: '20px !important',
    color: '#FFF !important',
    marginLeft: '20px !important',

    '@media (max-width: 960px)': {
        fontSize: '16px !important',
    }
})

const Navbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(!menuOpen);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    return (
        <Container maxWidth='xxl' sx={{ position: 'relative', background: 'linear-gradient(180deg, rgba(0,0,0,1) 4%, rgba(0,212,255,0) 100%)', padding: '0px', margin: '0px', zIndex: '10' }}>
            <Container maxWidth='xl'>
                <Box style={{ padding: '10px 0px 10px 0px' }}>
                    <Box sx={{ height: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {/* <img src='./images/Juvalfri-navbar.svg' style={{ width: '140px', height: 'auto' }} alt='logo-juvalfri'></img> */}
                        <Link to='/'>
                            <Box>
                                <JuvalfriAnimation width={"165"} height={"48.86"} scale={"1"} />
                            </Box>
                        </Link>

                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <BasicMenu />
                                </div>
                                <Link to='/membranas' style={{ textDecoration: 'none' }}>
                                    <ButtonMenu color="inherit">Membranas</ButtonMenu>
                                </Link>
                                <Link to='/manufactura' style={{ textDecoration: 'none' }}>
                                    <ButtonMenu color="inherit">Manufactura</ButtonMenu>
                                </Link>
                                <Link to='/' style={{ textDecoration: 'none' }}>
                                    <ButtonMenu color="inherit">Servicio</ButtonMenu>
                                </Link>
                                <Link to='/contacto' style={{ textDecoration: 'none' }}>
                                    <ButtonMenu color="inherit">Contacto</ButtonMenu>
                                </Link>
                            </div>
                        </Box>
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            sx={{ mr: 0, display: { md: "none" }, color: '#FFF' }}
                            onClick={handleClick}
                        >
                            {menuOpen ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </Box>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                        "& .MuiMenu-paper": {
                            height: "100vw",
                            width: "100vw",
                            transformOrigin: "top left",
                            background: '#FFF',
                            borderRadius: '8px'
                        },
                        transition: "transform 0.3s ease-in-out",
                        backdropFilter: 'blur(3px)',
                        backgroundPositionY: '80px'
                    }}
                >
                    <Container sx={{ p: 1 }}>
                        <CloseIcon sx={{ color: '#FFF', position: 'fixed', top: '24px', right: '44px' }} onClick={handleClose} />
                        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<KeyboardArrowDownIcon sx={{ color: '#999', m: '0px' }} />}
                                    sx={{ background: '#fff', margin: '0px !important', fontWeight: '600', fontSize: '20px', display: 'flex', justifyContent: 'space-between', color: '#333', "& p": { m: 0 } }}
                                >
                                    <p>Productos</p>
                                </AccordionSummary>
                                <AccordionDetails sx={{ display: 'flex', flexFlow: 'column', background: '#fff' }}>
                                    <Link to='/catalogoMembranas' style={{ textDecoration: 'none' }}>
                                        <ListItem onClick={handleClose} sx={{ background: '#fff', fontWeight: '600', fontSize: '20px', display: 'flex', justifyContent: 'space-between', color: '#333', p: 2.5, "& p": { m: 0 } }} ><p>Catálogo de Membranas</p><KeyboardArrowRightIcon sx={{ color: '#999' }} /></ListItem>
                                    </Link>
                                    <Link to='/catalogoRefacciones' style={{ textDecoration: 'none' }}>
                                        <ListItem onClick={handleClose} sx={{ background: '#fff', fontWeight: '600', fontSize: '20px', display: 'flex', justifyContent: 'space-between', color: '#333', p: 2.5, "& p": { m: 0 } }} ><p>Catálogo de Refacciones</p><KeyboardArrowRightIcon sx={{ color: '#999' }} /></ListItem>
                                    </Link>
                                </AccordionDetails>
                            </Accordion>
                            <Link to='/membranas' style={{ textDecoration: 'none' }}>
                                <ListItem onClick={handleClose} sx={{ background: '#fff', fontWeight: '600', fontSize: '20px', display: 'flex', justifyContent: 'space-between', color: '#333', p: 2.5, "& p": { m: 0 } }} ><p>Membranas</p><KeyboardArrowRightIcon sx={{ color: '#999' }} /></ListItem>
                            </Link>
                            <Link to='/manufactura' style={{ textDecoration: 'none' }}>
                                <ListItem onClick={handleClose} sx={{ background: '#fff', fontWeight: '600', fontSize: '20px', display: 'flex', justifyContent: 'space-between', color: '#333', p: 2.5, "& p": { m: 0 } }} ><p>Manufactura</p><KeyboardArrowRightIcon sx={{ color: '#999' }} /></ListItem>
                            </Link>
                            <Link to='/contacto' style={{ textDecoration: 'none' }}>
                                <ListItem onClick={handleClose} sx={{ background: '#fff', fontWeight: '600', fontSize: '20px', display: 'flex', justifyContent: 'space-between', color: '#333', p: 2.5, "& p": { m: 0 } }} ><p>Contacto</p><KeyboardArrowRightIcon sx={{ color: '#999' }} /></ListItem>
                            </Link>
                        </Box>

                    </Container>
                </Menu>
            </Container>
        </Container>
    );
};

function BasicMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    let navigate = useNavigate();

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <ButtonMenu
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Productos <KeyboardArrowDownIcon sx={{ color: '#FFF' }} />
            </ButtonMenu>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Link to='/catalogoMembranas' style={{ textDecoration: 'none' }}>
                    <MenuItem onClick={handleClose} style={{ color: '#FFF' }}>Catálogo de Membranas</MenuItem>
                </Link>
                < Link to='/catalogoRefacciones' style={{ textDecoration: 'none' }}>
                    <MenuItem onClick={handleClose} style={{ color: '#FFF' }}>Catálogo de Refacciones</MenuItem>
                </Link>
            </Menu>
        </div>
    );
}

export default Navbar;
