import React from 'react';

import styled from 'styled-components';

const StyledDiv = styled.div({
    position: 'absolute',
    display: 'block',
    top: '0px',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',

    '& .bg-xl': {


        '@media (max-width: 1920px)': {
            width: '100%',
            opacity: '80%',
            marginTop: '-50px',
            height: 'auto',
            marginLeft: '0px',
            transition: 'all 0.3s ease-in-out',
            display: 'block',
        },

        '@media (max-width: 1280px)': {
            display: 'none',
        },

        '@media (max-width: 960px)': {
            display: 'none',
        },
    },

    '& .bg-lg': {
        display: 'none',

        '@media (max-width: 1920px)': {
            display: 'none',
        },

        '@media (max-width: 1280px)': {
            width: '130%',
            height: 'auto',
            opacity: '80%',
            marginTop: '-100px',
            marginLeft: '-350px',
            // transform: 'scaleY(-1)',
            display: 'block',
            transition: 'all 0.3s ease-in-out',
        },

        '@media (max-width: 960px)': {
            display: 'none',
        }
    },

    '& .bg-md': {
        display: 'none',

        '@media (max-width: 1920px)': {
            display: 'none',
        },

        '@media (max-width: 1280px)': {
            display: 'none',
        },

        '@media (max-width: 960px)': {
            width: '150%',
            height: 'auto',
            opacity: '80%',
            marginTop: '-100px',
            marginLeft: '-380px',
            // transform: 'scaleY(-1)',
            display: 'block',
            transition: 'all 0.3s ease-in-out',
        },

        '@media (max-width: 600px)': {
            display: 'none',
        }
    },

    '& .bg-sm': {
        display: 'none',

        '@media (max-width: 1920px)': {
            display: 'none',
        },

        '@media (max-width: 1280px)': {
            display: 'none',
        },

        '@media (max-width: 960px)': {
            display: 'none',
        },

        '@media (max-width: 600px)': {
            width: '120%',
            height: 'auto',
            // transform: 'rotate(90deg)',
            marginTop: '-300px',
            opacity: '30%',
            marginLeft: '80px',
            display: 'block',
            transition: 'all 0.3s ease-in-out',
        },

        '@media (max-width: 420px)': {
            display: 'none',
        }
    },

    '& .bg-ssm': {
        display: 'none',

        '@media (max-width: 1920px)': {
            display: 'none',
        },

        '@media (max-width: 1280px)': {
            display: 'none',
        },

        '@media (max-width: 960px)': {
            display: 'none',
        },

        '@media (max-width: 600px)': {
            display: 'none',
        },
        '@media (max-width: 420px)': {
            width: '100%',
            height: 'auto',
            opacity: '30%',
            // transform: 'rotate(90deg)',
            marginTop: '-300px',
            marginLeft: '120px',
            display: 'block',
            transition: 'all 0.3s ease-in-out',
        }
    },
})

function MembranasBackground() {
    return (
        <StyledDiv>
            <picture>
                <source srcSet='./images/manufactura/lampara-cirugia.webp' type='image/webp' />
                <source srcSet='./images/manufactura/lampara-cirugia.png' type='image/png' />
                <img alt='bg-main' src='./images/manufactura/lampara-cirugia.webp' className='bg-xl' />
            </picture>
            <picture>
                <source srcSet='./images/manufactura/lampara-cirugia.webp' type='image/webp' />
                <source srcSet='./images/manufactura/lampara-cirugia.png' type='image/png' />
                <img alt='bg-main' src='./images/manufactura/lampara-cirugia.webp' className='bg-lg' />
            </picture>
            <picture>
                <source srcSet='./images/manufactura/lampara-cirugia.webp' type='image/webp' />
                <source srcSet='./images/manufactura/lampara-cirugia.png' type='image/png' />
                <img alt='bg-main' src='./images/manufactura/lampara-cirugia.webp' className='bg-md' />
            </picture>
            <picture>
                <source srcSet='./images/steris-membranas.webp' type='image/webp' />
                <source srcSet='./images/steris-membranas.png' type='image/png' />
                <img alt='bg-main' src='./images/steris-membranas.webp' className='bg-sm' />
            </picture>
            <picture>
                <source srcSet='./images/steris-membranas.webp' type='image/webp' />
                <source srcSet='./images/steris-membranas.png' type='image/png' />
                <img alt='bg-main' src='./images/steris-membranas.webp' className='bg-ssm' />
            </picture>
        </StyledDiv>
    );
}

export default MembranasBackground;
