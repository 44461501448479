import React from 'react';

const Maps = (props) => {
    return (
        <div style={{ overflow: 'hidden', height: '100%', padding: '0px', margin: '0px', maxHeight: '450px' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1068.3616704362814!2d-99.18525508313355!3d19.462537274364205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f89c800eaaa9%3A0xc78166c7ae68333c!2sJUVALFRI!5e0!3m2!1ses-419!2smx!4v1709652357211!5m2!1ses-419!2smx" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
}

export default Maps;