import styled from 'styled-components';

export const StyledDivider = styled.div({
    marginTop: '16px',
    overflow: 'hidden',
    // marginBottom: '72px',

    '& img': {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',

        '@media (max-width: 1200px)': {
            width: '1800px',
            marginLeft: '-600px'
        },

        '@media (max-width: 900px)': {
            width: '1500px',
            marginLeft: '-600px'
        },

        '@media (max-width: 700px)': {
            width: '1500px',
            marginLeft: '-600px'
        },

        '@media (max-width: 600px)': {
            width: '1500px',
            marginLeft: '-800px'
        },

        '@media (max-width: 420px)': {
            width: '1500px',
            marginLeft: '-900px'
        },
    },

})

export const StyledDividerBottom = styled.div({
    marginTop: '98px',
    overflow: 'hidden',
    marginBottom: '120px',

    '& img': {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',

        '@media (max-width: 1200px)': {
            width: '1800px',
            marginRight: '-600px'
        },

        '@media (max-width: 900px)': {
            width: '1500px',
            marginRight: '-600px'
        },

        '@media (max-width: 700px)': {
            width: '1500px',
            marginRight: '-600px'
        },

        '@media (max-width: 600px)': {
            width: '1500px',
            marginRight: '-800px'
        },

        '@media (max-width: 420px)': {
            width: '1500px',
            marginRight: '-900px'
        },
    },

})

export const StyledTitle = styled.p({
    fontSize: '72px',
    textShadow: '1px 1px 2px #0d1117',
    fontWeight: '800',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '24px',
    lineHeight: '72px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '48px',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledSubtitle = styled.p({
    fontSize: '32px',
    textShadow: '1px 1px 2px #0d1117',
    transition: 'all 0.3s ease-in-out',
    // marginBottom: '128px',
    marginTop: '0px',
    maxWidth: '910px',
    lineHeight: '44px',
    color: '#FFF',
    paddingLeft: '12px',

    '@media (max-width: 900px)': {
        maxWidth: '600px',
    },

    '@media (max-width: 600px)': {
        fontSize: '24px',
        marginBottom: '64px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '20px',
        marginBottom: '64px',
        lineHeight: '28px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
    },
})

export const StyledButton = styled.button({
    // width: '200px',
    // height: '70px',
    // borderRadius: '20px',
    // border: 'none',
    // cursor: 'pointer',
    // transition: 'all 0.2s ease-in-out',
    // background: 'linear-gradient(60deg, #5b4aff 0%, #8b7aff 100%)',
    // color: '#FFF',
    // fontSize: '24px',
    // fontWeight: '600',
    // marginTop: '32px',
    // marginLeft: '12px',
    // fontFamily: 'Mona Sans',
    // boxShadow: '3px 3px 10px #0d1117',

    // '&:hover': {
    //     background: 'linear-gradient(60deg, #4b3aff 25%, #9b8aff 100%)',
    //     transition: 'all 0.2s ease-in-out',
    //     boxShadow: '3px 3px 10px #FFF',
    // },

    // '&:active': {
    //     background: 'linear-gradient(60deg, #2b1aDD 25%, #9b8aff 100%)',
    //     transition: 'all 0.2s ease-in-out',
    //     transform: 'scale(0.98) translate(0px, 2px)',
    //     boxShadow: '3px 3px 10px #FFFFFFAA',
    // },

    width: '200px',
    height: '70px',
    borderRadius: '20px',
    border: 'solid 2px #9b8aff',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #9b8aff',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #CbBaff',
        backgroundColor: '#00000022',
    },
})

export const StyledButtonMas = styled.button({
    width: '200px',
    height: '70px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    background: 'linear-gradient(60deg, #5b4aff 0%, #8b7aff 100%)',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',
    boxShadow: '3px 3px 10px #0d1117',

    '&:hover': {
        background: 'linear-gradient(60deg, #4b3aff 25%, #9b8aff 100%)',
        transition: 'all 0.2s ease-in-out',
        boxShadow: '3px 3px 10px #FFF',
    },

    '&:active': {
        background: 'linear-gradient(60deg, #2b1aDD 25%, #9b8aff 100%)',
        transition: 'all 0.2s ease-in-out',
        transform: 'scale(0.98) translate(0px, 2px)',
        boxShadow: '3px 3px 10px #FFFFFFAA',
    }
})

export const StyledSecondaryTitle = styled.p({
    fontSize: '28px',
    fontWeight: '500',
    transition: 'all 0.3s ease-in-out',
    lineHeight: '32px',
    color: '#FFF',
    paddingBottom: '98px',
    paddingLeft: '12px',
    maxWidth: '960px',
    marginTop: '128px',

    '@media (max-width: 600px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
        paddingBottom: '64px',
        marginTop: '96px',
    },

    '@media (max-width: 420px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#ffa28b',
    }
})

export const StyledContent = styled.p({
    fontSize: '28px',
    fontWeight: '400',
    transition: 'all 0.3s ease-in-out',
    lineHeight: '32px',
    color: '#FFFFEE',
    paddingBottom: '48px',
    paddingLeft: '12px',
    marginTop: '32px',

    '@media (max-width: 600px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
        paddingBottom: '64px',
        marginTop: '32px',
    },

    '@media (max-width: 420px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#7ee787',
        fontWeight: '500',
    }
})

export const StyledContent2 = styled.p({
    fontSize: '28px',
    fontWeight: '400',
    transition: 'all 0.3s ease-in-out',
    lineHeight: '32px',
    color: '#FFFFEE',
    paddingBottom: '48px',
    paddingLeft: '12px',
    marginTop: '32px',

    '@media (max-width: 600px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
        paddingBottom: '64px',
        marginTop: '32px',
    },

    '@media (max-width: 420px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#ffa28b',
        fontWeight: '500',
    }
})

export const StyledDivImage = styled.div({
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',

    '& img': {
        maxWidth: '800px',
        height: 'auto',
        transition: 'all 0.3s ease-in-out',
        borderRadius: '20px',
        boxShadow: '0px 0px 10px #0d1117',
        marginBottom: '128px',

        '&:hover': {
            transform: 'scale(1.015)',
        },

        '@media (max-width: 900px)': {
            width: '100%',
            maxWidth: '600px',
            marginTop: '36px',
            marginBottom: '128px',
            // transform: 'rotate(90deg)',
        },

    },

    '@media (max-width: 900px)': {
        justifyContent: 'start',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledButtonStepper1 = styled.button({
    width: '180px',
    height: '50px',
    borderRadius: '20px',
    border: 'solid 2px #7ee787',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #7ee787',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #65EB9D',
        backgroundColor: '#00000022',
    },

    '&:disabled': {
        backgroundColor: '#00000011',
        color: '#FFF',
        border: 'solid 2px #888',

        '&:hover': {
            boxShadow: '0px 0px 0px #7ee78700',
        },
    }
})

export const StyledButtonStepper2 = styled.button({
    width: '180px',
    height: '50px',
    borderRadius: '20px',
    border: 'solid 2px #9b8aff',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #9b8aff',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #CbBaff',
        backgroundColor: '#00000022',
    },

    '&:disabled': {
        backgroundColor: '#00000011',
        color: '#FFF',
        border: 'solid 2px #888',

        '&:hover': {
            boxShadow: '0px 0px 0px #7ee78700',
        },
    }
})

export const StyledDivImage2 = styled.div({
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
        width: '90%',
        height: 'auto',
        transition: 'all 0.3s ease-in-out',
        // borderRadius: '20px',
        boxShadow: '0px 0px 10px #0d1117',

        '@media (max-width: 900px)': {
            width: '90%',
            // transform: 'rotate(90deg)',
        },

    },

    '@media (max-width: 900px)': {
        justifyContent: 'start',
        transition: 'all 0.3s ease-in-out',
    },
})